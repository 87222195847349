import React from 'react'
import {connect} from "react-redux";
import {EventSourcePolyfill} from "event-source-polyfill";
import {BACKEND_URL} from "../config";
import {addActivityRecord, selectAllRecords, trackStart} from "./trackingDuck";
import BrowseTableComponent from "../engine/component/BrowseTableComponent";
import {importResultsTableConfiguration} from "../components/administration/import/importResultsTable.config";
import {trackingTableConfiguration} from "./trackingTable.config";

const mapStateToProps = (state, ownProps) => {
    return {
        records: selectAllRecords(state)
    }
        ;
};
const mapDispatchToProps = (dispatch) => {
    return {
        addActivityRecord: (record) => dispatch(addActivityRecord(record)),
        trackStart: () => dispatch(trackStart())
    }
};

class UserActivity extends React.Component {

    es = null;

    componentWillMount() {
        this.prepare();
    }

    prepare() {
        this.props.trackStart();

        if (!this.es) {
            this.es = new EventSourcePolyfill(BACKEND_URL + "users-activity/sse", {
                headers: {
                    'Authorization': window.sessionStorage.accessToken
                }
            });

            let self = this;
            this.es.onmessage = function (event) {
                self.addActivity(event);
            }
        }
    }

    addActivity(event) {
        this.props.addActivityRecord(JSON.parse(event.data));
    }


    render() {

        let resultsTable = null;

        if (this.props.records) {
            resultsTable = (
                <BrowseTableComponent tableConfig={trackingTableConfiguration} records={this.props.records}/>
            );
        }

        return (
            <div>
                <h3>Historia zmian</h3>
                {resultsTable}
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(UserActivity)