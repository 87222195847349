import React from 'react'
import {connect} from "react-redux";
import {getFolders} from "../../actions/foldersActions";
import Select from "react-select";
import {getAvailableFoldersForReactSelect} from "../../selectors/availableFoldersForReactSelect.selector";
import {setAttribute} from "../../actions/artworkActions";
import {Translation} from "react-i18next";
import ToggleEditable from "./ToggleEditable";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";

const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, "folders"),
        selectedFolders: state.artworks.artwork.folders && state.artworks.artwork.folders.map(folder => {
            return {
                label: folder.labels.pl,
                value: folder
            }
        }),
        availableFolders: getAvailableFoldersForReactSelect(state)
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getFolders: () => dispatch(getFolders()),
        setFolders: (folders) => dispatch(setAttribute("folders", folders)),
    }
};

class FoldersEditableField extends React.Component {

    componentDidMount() {
        this.props.getFolders();
    }

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setFolders(inputValue.map(viewFolder => viewFolder.value));
            default:
                break;
        }
    };

    render() {
        return (
            <div className="component">
                <header className="component-header">
                    <i className="icon-minus"></i>
                    <Translation>{t =>
                        <span>{t("folders")}</span>
                    }</Translation>
                    <ToggleEditable fieldId={"folders"}/>
                </header>
                <div className="component-message">

                </div>
                <div className="component-content">
                    <Select
                        placeholder={""}
                        isDisabled={!this.props.enabled}
                        value={this.props.selectedFolders}
                        onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                        isMulti={true}
                        defaultOptions
                        options={this.props.availableFolders}
                    />

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FoldersEditableField)

