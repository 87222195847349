import React from 'react'
import {connect} from "react-redux";
import PersonEditableField from "./PersonEditableField";
import {setAttribute} from "../../actions/artworkActions";
import {Translation} from "react-i18next";
import ToggleEditable from "./ToggleEditable";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, "authors"),
        authors: state.artworks.artwork.authors ? state.artworks.artwork.authors : []
    }

};
const mapDispatchToProps = (dispatch) => {
    return {
        setAuthors: (person) => dispatch(setAttribute("authors", person))
    }
};

class AuthorsEditableField extends React.Component {


    render() {

        return (
            <div className="component">
                <header className="component-header">
                    <Translation>{t =>
                        <span>{t("authors")}</span>
                    }</Translation>
                    <ToggleEditable fieldId={"authors"}/>
                </header>
                <div className="message">

                </div>
                <div className="component-content">
                    <PersonEditableField
                        records={this.props.authors}
                        isDisabled={!this.props.enabled}
                        setterAction={this.props.setAuthors}
                        mappingToDbPath={"author"}
                    />
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorsEditableField)

