import React from 'react'
import {connect} from "react-redux";
import {removeChild} from "../../actions/artworkActions";
import {withRouter} from "react-router-dom";

const mapStateToProps = (state, ownProps) => {
    return {};
};
const mapDispatchToProps = (dispatch) => {
    return {
        removeChild: (parentId, childId) => dispatch(removeChild(parentId, childId))
    };
};

class ArtworkChild extends React.Component {

    goToChild() {
        this.props.history.push("/artwork/edit/" + this.props.artwork.id);
    }


    render() {

        let removeIcon = null;

        if (this.props.parentArtworkId) {
            removeIcon = <i className="fas fa-times" onClick={(event) => {
                this.props.removeChild(this.props.parentArtworkId, this.props.artwork.id);
                event.stopPropagation()
            }}/>
        }


        return (
            <li className="list-group-item" onClick={() => this.goToChild()}>
                <span>{this.props.artwork.inventoryNumber}, </span>
                <span>{getArtworkTitle(this.props.artwork)}</span>
                {removeIcon}
            </li>
        )
    }


}

function getArtworkTitle(artwork) {
    if (!artwork.title) {
        return null;
    }

    return artwork.title.replace(/(<([^>]+)>)/ig, '')
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArtworkChild))