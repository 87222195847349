import {loadDataToAssetRepositoryView} from "./loadDataToAssetRepositoryView";
import {CURRENT_RECORD} from "../../../engine/constraint/base/currentRecord";
import {FIELD_IS_EQUAL} from "../../../engine/constraint/qualifier/fieldIsEqual";
import React from 'react';
import {BACKEND_URL} from "../../../config";


export const repositoriesTableConfiguration = {
    id: "repositoriesTableV1",
    columns: [
        {
            id: "id",
            label: "id",
        },
        {
            id: "location",
            label: "location",
            renderer: item => {

                let icon = null;
                if (!item.locationOk) {
                    icon = <i className="fas fa-exclamation-triangle" style={{color:"red"}}/>
                }

                return <React.Fragment>{item.location} {icon} </React.Fragment>
            }
        },
        {
            id: "thumbnailLocation",
            label: "thumbnailLocation",
            renderer: item => {

                let icon = null;
                if (!item.thumbnailLocationOk) {
                    icon = <i className="fas fa-exclamation-triangle" style={{color:"red"}}/>
                }

                return <React.Fragment>{item.thumbnailLocation} {icon} </React.Fragment>
            }
        },
        {
            id: "default",
            label: "default",
            renderer: (item) => item.default ? <i className="fas fa-check" style={{color:"green"}} title="is up to date"/> : ""
        },
    ],
    rowActions: [
        {
            label: "default",
            constraint: {
                source: {
                    type: CURRENT_RECORD,
                    options: {}
                },
                qualifier: {
                    type: FIELD_IS_EQUAL,
                    options: {
                        field: "default",
                        value: true
                    }
                },
                negation: true,
            },
            action: {
                children: [
                    {
                        type: "block-component",
                        componentId: "assetRepositoriesMain"
                    },
                    {
                        type: "call-rest",
                        url: BACKEND_URL + 'asset-repository/{assetRepositoryId}/mark-default',
                        urlBuilder: {
                            assetRepositoryId: "id"
                        },
                        successAction: loadDataToAssetRepositoryView,
                        errorAction: {
                            children: [
                                {
                                    type: "unblock-component",
                                    componentId: "assetRepositoriesMain"
                                }
                            ]
                        }
                    }
                ]
            }
        },
    ],
    onRowClick: {
            type: "SET_RECORD_TO_COMPONENT",
            componentId: "repositoryForm",
            children: [
                {
                    type: "show-component",
                    componentId: "repositoriesFormHeaderEdit"
                },
                {
                    type: "hide-component",
                    componentId: "repositoriesFormHeaderAdd"
                },
                {
                    type: "show-component",
                    componentId: "repositoryFormMain"
                }
            ]
    },
    recordIdentifier: (entry) => entry.id,
}