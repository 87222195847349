import React from 'react'
import {connect} from "react-redux";
import ArtworkChild from "./ArtworkChild";
import * as actions from "../../actions/artworkActions";
import {Translation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        parent: state.artworks.parent,
        artworkId: state.artworks.artwork.id
    }
        ;
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtworkParent: (artworkId) => dispatch(actions.getArtworkParent(artworkId)),
    };
};

class ArtworkParent extends React.Component {

    componentWillMount() {
        this.prepare();
    }

    componentDidUpdate() {
        this.prepare();
    }

    prepare() {
        if (this.props.artwork && this.props.artwork._parentObject
            && (!this.props.parent || this.props.parent.id != this.props.artwork._parentObject.id)) {
            this.props.getArtworkParent(this.props.artwork._parentObject.id);
        }
    }

    render() {

        if (this.props.artwork._parentObject) {
            return (
                <div className="children-list-component">
                    <Translation>{t =>
                        <h6>{t("parentArtwork")}</h6>
                    }</Translation>

                    {this.props.parent && <div>
                        <ArtworkChild artwork={this.props.parent}/>
                    </div>}
                </div>
            );
        }

        return null;
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ArtworkParent)