import React from "react";

export const usersListTableConfiguration = {
    id: "usersTableV1",
    manageColumns: false,
    columns: [
        {
            id: "id",
            label: "id",
            default: true,
            required: true
        },
        {
            id: "roles",
            label: "roles",
            default: true,
            renderer: (artwork) => concateRoles(artwork.roles),
            trimToLength: 36
        },
        {
            id: "userGroups",
            label: "userGroups",
            default: true,
            renderer: (artwork) => concatUserGroups(artwork.userGroups),
            // trimToLength: 36
        },
        {
            id: "locked",
            label: "locked",
            default: true,
            renderer: (artwork) => artwork.nonLocked ? null : "YES",
        },

    ],
    isSelectable: false,
    recordIdentifier: (artwork) => artwork.id,
    onRowClick: (artwork) => window.location.href = "/administration/user/edit/" + artwork.id,
    rowRendererHelper: (artwork) => {
        return {"deleted": !artwork.nonLocked}
    }
}


export function concateRoles(roles) {
    if (!Array.isArray(roles)) {
        return null
    }

    return roles.join(", ")
}

export function concatUserGroups(userGroups) {
    if (!Array.isArray(userGroups)) {
        return null
    }

    return userGroups
        .filter(e => e != null)
        .map(e => e.name && e.name.pl ? e.name.pl : e.id)
        .map(e => <li>{e}</li>)
    // .join(', ')
}
