import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "../../components/item/ToggleEditable";
import SimpleEditableFieldInline from "./SimpleEditableFieldInline";
import SelectizeEditableFieldInline from "./SelectizeEditableFieldInline";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "homeAddress"),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}
};


class HomeAddressEditableField extends React.Component {
    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("homeAddress")}</span>
                        <ToggleEditable fieldId={"homeAddress"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <SimpleEditableFieldInline path="homeAddress.street" label="street" type="input"
                                                   enabledOverride={this.props.enabled}/>
                        <SimpleEditableFieldInline path="homeAddress.postalCode" label="postalCode" type="input"
                                                   enabledOverride={this.props.enabled}/>
                        <SelectizeEditableFieldInline path="homeAddress.city" dictionaryId="cities" label="city"
                                                      enabledOverride={this.props.enabled} dictionaryId="members_city"/>
                        <SelectizeEditableFieldInline path="homeAddress.country" dictionaryId="countries"
                                                      label="country"
                                                      enabledOverride={this.props.enabled}
                                                      dictionaryId="members_country"/>
                    </div>

                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomeAddressEditableField)

