import {BACKEND_URL} from "../../../config";

export const loadDataToAssetRepositoryView = {
    type: "call-rest",
    url: BACKEND_URL + 'asset-repository/',
    setResponseToComponent: "repositoriesTable",
    successAction: {
        children: [
            {
                type: "unblock-component",
                componentId: "assetRepositoriesMain"
            }
        ]
    },
    errorAction: {
        children: [
            {
                type: "unblock-component",
                componentId: "assetRepositoriesMain"
            }
        ]
    }
}