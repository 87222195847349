
export const exportResultsTableConfiguration = {
    id: "exportResultsTableV1",
    columns: [
        {
            id: "artworkId",
            label: "artworkId",
        },
        {
            id: "inventoryNumber",
            label: "inventoryNumber",
        },
        {
            id: "dateTime",
            label: "dateTime",
        },
        {
            id: "failed",
            label: "failed",
            renderer: (item) => item.failed ? "FAILED" : ""
        }
    ],
    recordIdentifier: (entry) => entry.id,
}