import React from 'react'
import {connect} from "react-redux";

import {getUsers} from "./usersDuck";
import BrowseTableComponent from "../../../engine/component/BrowseTableComponent";
import {usersListTableConfiguration} from "./usersListTable.config";

const mapStateToProps = (state) => {
    return {
        content: state.users.users
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtworks: (page) => dispatch(getUsers()),
        // openPickUpWindow: (value) => dispatch(openClosePickupWindow(true)),
    }
};

class UsersListComponent extends React.Component {

    componentDidMount() {
        if (!this.props.content || this.props.content.length == 0) {
            this.props.getArtworks(0);
        }
    }

    createNewArtwork() {
        window.location.href = "/artwork/create";
    }

    render() {

        return (
            <div className="row">
                <div className="col">
                    <BrowseTableComponent tableConfig={usersListTableConfiguration}
                                          records={this.props.content}/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListComponent)

