import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_ADD_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import {viewToDbMapper} from "./OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "./ToggleEditable";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "materialTechnique"),
        materials: getOptions(state, "materials"),
        techniques: getOptions(state, "techniques"),
        filigran: getOptions(state, "filigran")
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setMaterialTechnique: (type, dictionaryEntry) => dispatch(setAttributeSecondLevel("materialTechnique",type, dictionaryEntry)),
        openCreateDictionaryEntryModal: (dictionaryType, label) =>  dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "materialTechnique",
                subattr: dictionaryEntryTypeToFieldType(dictionaryType)
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.materialTechnique && Array.isArray(state.artworks.artwork.materialTechnique[type]) ?
    state.artworks.artwork.materialTechnique[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];

const fieldTypeToDictionaryEntryType = (type) => {
    switch (type) {
        case "filigran":
            return "filigrans";
        default:
            return type;
    }
};

const dictionaryEntryTypeToFieldType = (type) => {
    switch (type) {
        case "filigrans":
            return "filigran";
        default:
            return type;
    }
};

class MaterialTechniqueEditableField extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length-1].label;
                this.props.openCreateDictionaryEntryModal(fieldTypeToDictionaryEntryType(type), label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setMaterialTechnique(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("materialTechnique")}</span>
                        <ToggleEditable fieldId={"materialTechnique"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("materialTechnique.materials")}</div>
                            <div className="col">
                                <AsyncCreatableSelect
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    value={this.props.materials}
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    onChange={(inputValue, actionMeta) => this.handleChange("materials", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("materials", inputValue)}
                                />

                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("materialTechnique.techniques")}</div>
                            <div className="col">
                                <AsyncCreatableSelect
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    value={this.props.techniques}
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    onChange={(inputValue, actionMeta) => this.handleChange("techniques", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("techniques", inputValue)}
                                />

                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("materialTechnique.filigran")}</div>
                            <div className="col">
                                <AsyncCreatableSelect
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    value={this.props.filigran}
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    onChange={(inputValue, actionMeta) => this.handleChange("filigran", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("filigrans", inputValue)}
                                />

                            </div>
                        </div>
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MaterialTechniqueEditableField)

