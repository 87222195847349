import moment from "moment";

export const trackingTableConfiguration = {
    columns: [
        {
            id: "user",
            label: "user",
        },
        {
            id: "inventoryNumber",
            label: "inventoryNumber",
        },
        {
            id: "at",
            label: "at",
            renderer: (entry) => moment(entry.at).format("HH:mm:ss, YYYY-MM-DD-MM")
        },
    ],
    recordIdentifier: (entry) => entry.user + entry.inventoryNumber + entry.at,
}