import React from "react";

export const usersGroupsListTableConfiguration = {
    id: "usersGroupsTableV1",
    manageColumns: false,
    columns: [
        {
            id: "name",
            label: "name",
            default: true,
            renderer: (ug) => ug.name.pl,
            required: true
        },
        {
            id: "description",
            label: "description",
            default: true,
            renderer: (ug) => ug.description.pl,
            required: true
        },
        {
            id: "roles",
            label: "roles",
            renderer: (ug) => ug.mainRole + ", " + (ug.roles ? ug.roles.join(", ") : ""),
            default: true,
            required: true,
        },

    ],
    isSelectable: false,
    recordIdentifier: (artwork) => artwork.id,
    onRowClick: (artwork) => window.location.href = "/administration/user-groups/edit/" + artwork.id,

}

