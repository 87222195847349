import ItemDetails from "../components/item/ItemDetails";

export const artworkEditScreen = {
    id: "artworkEditScreen",
    type: "custom-component",
    component: ItemDetails,
    route: {
        path: "/artwork/edit/:inventoryNumber",
        exact:true
    }
};

export const artworkCreateScreen = {
    id: "artworkCreateScreen",
    type: "custom-component",
    component: ItemDetails,
    route: {
        path: "/artwork/create",
        exact:true
    }
};


export const artworkCreateChildScreen = {
    id: "artworkCreateScreenChild",
    type: "custom-component",
    component: ItemDetails,
    route: {
        path: "/artwork/create-child/:parentArtworkId",
        exact: true
    }
};


export const artworkCloneScreen = {
    id: "artworkCloneScreen",
    type: "custom-component",
    component: ItemDetails,
    route: {
        path: "/artwork/clone/:cloneInventoryNumber",
        exact:true
    }
};