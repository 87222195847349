import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_SET_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import AsyncCreatableSelect from "react-select/async-creatable";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import ToggleEditable from "./ToggleEditable";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const getDatePartFromState = (state, dateType, yearMonthDay) => {
    let artwork = state.artworks.artwork;
    let dictionaryEntry = state.artworks.artwork.dateOfCreation ? state.artworks.artwork.dateOfCreation.entry : null;

    if (dictionaryEntry) {
        return dictionaryEntry.data
        && dictionaryEntry.data.date
        && dictionaryEntry.data.date[dateType]
            ? dictionaryEntry.data.date[dateType][yearMonthDay] : '';
    } else {
        return artwork.dateOfCreation
        && artwork.dateOfCreation[dateType]
            ? artwork.dateOfCreation[dateType][yearMonthDay] : '';
    }
};

const selectUncertain = (state) => {
    let dictionaryEntry = state.artworks.artwork.dateOfCreation ? state.artworks.artwork.dateOfCreation.entry : null;

    if (dictionaryEntry) {
        return dictionaryEntry.data
        && dictionaryEntry.data.date
            ? dictionaryEntry.data.date.uncertain : false;
    } else {
        return state.artworks.artwork.dateOfCreation ? state.artworks.artwork.dateOfCreation.uncertain : false
    }

}

const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, "dateOfCreation"),
        entry: state.artworks.artwork.dateOfCreation && state.artworks.artwork.dateOfCreation.entry ?
            toSelectizeDictionaryEntry(state.artworks.artwork.dateOfCreation)
            : null,
        unknown: state.artworks.artwork.dateOfCreation ? state.artworks.artwork.dateOfCreation.unknown : false,
        uncertain: selectUncertain(state),
        fromYear: getDatePartFromState(state, "from", "year"),
        fromMonth: getDatePartFromState(state, "from", "month"),
        fromDay: getDatePartFromState(state, "from", "day"),
        toYear: getDatePartFromState(state, "to", "year"),
        toMonth: getDatePartFromState(state, "to", "month"),
        toDay: getDatePartFromState(state, "to", "day")
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttr: (atttr, value) => dispatch(setAttributeSecondLevel("dateOfCreation", atttr, value)),
        setDateOfCreationEntry: (entry) => dispatch(setAttributeSecondLevel("dateOfCreation", "entry", entry)),
        openCreateDictionaryEntryModal: (label) => dispatch(openCreateDictionaryEntryModal("dates", label, {
            actionType: ARTWORK_SET_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "dateOfCreation",
                subattr: "entry"
            }
        })),
    }
};

class DateOfCreationEditableField extends React.Component {

    setFromDate(yearMonthDay, value) {
        let from = {
            year: this.props.fromYear,
            month: this.props.fromMonth,
            day: this.props.fromDay,
        };

        from[yearMonthDay] = value;


        this.props.setAttr("from", from);
    }

    setToDate(yearMonthDay, value) {
        let to = {
            year: this.props.toYear,
            month: this.props.toMonth,
            day: this.props.toDay,
        };

        to[yearMonthDay] = value;


        this.props.setAttr("to", to);
    }

    promiseOptions = inputValue => {
        return getDictionaryEntriesByLabel("dates", inputValue);
    };

    handleChange = (inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let setterSpec = {
                    attr: "dateOfCreation",
                    subattr: "entry"
                };
                this.props.openCreateDictionaryEntryModal(inputValue.value);
                break;
            case "select-option":
                this.props.setDateOfCreationEntry(inputValue.dictionaryEntry);
                break;
            case "clear":
                this.props.setDateOfCreationEntry(null);
            default:
                break;
        }

    };

    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("dateOfCreation")}</span>
                        <ToggleEditable fieldId={"dateOfCreation"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <div className="row form-line">
                            <div className="col">
                                <AsyncCreatableSelect
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    isClearable
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    value={this.props.entry}
                                    onChange={this.handleChange}
                                    defaultOptions
                                    loadOptions={this.promiseOptions}
                                />
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col">
                                <input placeholder="Rok" type="number" name="dateOfCreationFromYear"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.fromYear}
                                       onChange={(e) => this.setFromDate("year", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Miesiąc" type="number" name="dateOfCreationFromMonth"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.fromMonth}
                                       onChange={(e) => this.setFromDate("month", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Dzień" type="number" name="dateOfCreationFromDay"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.fromDay}
                                       onChange={(e) => this.setFromDate("day", e.target.value)}
                                />
                            </div>
                        </div>


                        <div className="row form-line">
                            <div className="col">
                                <input placeholder="Rok" type="number" name="dateOfCreationToYear"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.toYear}
                                       onChange={(e) => this.setToDate("year", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Miesiąc" type="number" name="dateOfCreationToMonth"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.toMonth}
                                       onChange={(e) => this.setToDate("month", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Dzień" type="number" name="dateOfCreationToDay"
                                       readOnly={this.props.entry}
                                       disabled={!this.props.enabled}
                                       value={this.props.toDay}
                                       onChange={(e) => this.setToDate("day", e.target.value)}
                                />
                            </div>
                        </div>
                        <div className="row">

                            <div className="col">
                                <input type="checkbox"
                                       id="uncertainDateOfCreation"
                                       disabled={this.props.entry || !this.props.enabled}
                                       checked={this.props.uncertain}
                                       onChange={(e) => this.props.setAttr("uncertain", e.target.checked)}/>

                                <label htmlFor="uncertainDateOfCreation">Niepewna</label>
                            </div>
                            <div className="col">
                                <input type="checkbox"
                                       id="unknownDateOfCreation"
                                       disabled={this.props.entry || !this.props.enabled}
                                       checked={this.props.unknown}
                                       onChange={(e) => this.props.setAttr("unknown", e.target.checked)}/>

                                <label htmlFor="unknownDateOfCreation">Nieznana</label>
                            </div>
                            <div className="col">

                            </div>
                        </div>


                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DateOfCreationEditableField)

