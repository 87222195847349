import {select} from 'redux-saga/effects';

export const getFormState = (state, form) =>
    (state && state.finalForm && state.finalForm[form]) || {}

export const getFormValues = (state, form) =>
    (state && state.finalForm && state.finalForm[form].values) || {}


export const selectFormValues = (formId) => select(state => getFormValues(state, formId))

export const selectComponentData = (componentId) => select(state => state.engine.components[componentId].data)

export const getComponentState = (state, componentId) => state.engine.components[componentId];

export const getComponentStateAttr = (state, componentId, attr) => state.engine.components[componentId] ? state.engine.components[componentId][attr] : null;
//
// export const getComponentStateAttr = (componentId, attr) => {return (state) => {
//     console.log(state);
//     return state.engine.components[componentId] ? state.engine.components[componentId][attr] : null}};

export const selectMainConfig = () => select(state => state.engine.config)








export function findConfigRecursive(config, configId) {
    console.log(config, configId)
    if (config.id == configId) {
        return config;
    }

    if (config.children && config.children.length > 0) {
        for (let idx in config.children) {
            let result = findConfigRecursive(config.children[idx], configId);
            if (result) {
                return result;
            }
        }
    }

    return null;
}

export const getUserPermissions = (state, p1, p2) => {
    if (!state.engine.components.loggedUserComponent || !state.engine.components.loggedUserComponent.data
        || !state.engine.components.loggedUserComponent.data.permissions) {
        return {}
    }

    if (!state.engine.components.loggedUserComponent.data.permissions[p1]) {
        return {}
    }

    if (!state.engine.components.loggedUserComponent.data.permissions[p1][p2]) {
        return {}
    }

    return state.engine.components.loggedUserComponent.data.permissions[p1][p2]
}