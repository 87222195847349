import {assetsTableConfig} from "./assetsTableConfig";
import {loadDataToAssetRepositoryView} from "../asset-repositories/loadDataToAssetRepositoryView";
import {repositoriesTableConfiguration} from "../asset-repositories/repositoriesTable.config";
import {BACKEND_URL} from "../../../config";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const assetsConfig = {
    id: "assetMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/assets",
        exact: true
    },
    children: [
        {
            id: "assetsHeader",
            type: "header",
            content: "Assets ",

        },
        {
            id: "assetsTable",
            type: "ajax-table",
            tableConfig: assetsTableConfig,
            searchForm: {
                fields: [
                    {
                        label: "Filename",
                        placeholder: "Filename",
                        name: "someString",
                        type: "input",
                    },
                    {
                        label: "Repository",
                        placeholder: "Repository",
                        name: "repository",
                        type: "input",
                    }
                ]
            },
            dataSource: {
                type: "ajax",
                method: "POST",
                url: BACKEND_URL + 'asset/search',
            }
        },
    ]
}
