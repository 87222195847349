import ContentListComponent from "./ContentListComponent";
import ContentEditComponent from "./ContentEditComponent";


export const contentListScreen = {
    id: "contentListScreen",
    type: "custom-component",
    component: ContentListComponent,
    route: {
        path: "/content",
        exact: true
    }
};

export const contentEditConfig = {
    id: "contentEditScreen",
    type: "custom-component",
    component: ContentEditComponent,
    route: {
        path: "/content/edit/:id",
        exact: true
    }
}

export const contentCreateConfig = {
    id: "contentCreateScreen",
    type: "custom-component",
    component: ContentEditComponent,
    route: {
        path: "/content/create",
        exact: true
    }
}