import ArtworksListComponent from "../components/browse/ArtworksListComponent";

export const artworksListScreen = {
    id: "artworksListScreen",
    type: "custom-component",
    component: ArtworksListComponent,
    route: {
        path: "/artworks",
        exact: false
    }
};