import React from "react";

export const contentListTableConfiguration = {
    id: "contentTableV1",
    manageColumns: false,
    columns: [
        {
            id: "titlePl",
            label: "titlePl",
            default: true,
            renderer: (content) => content.title.pl,
            required: true
        },
        {
            id: "titleEn",
            label: "titleEn",
            default: true,
            renderer: (content) => content.title.en,
            required: true
        },
        {
            id: "lang",
            label: "lang",
            default: true,
            renderer: (content) => content._langVersion ? content._langVersion.labels.pl : null,
            required: true
        },
    ],
    isSelectable: false,
    recordIdentifier: (content) => content.id,
    onRowClick: (content) => window.location.href = "/content/edit/" + content.id,

}

