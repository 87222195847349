import NewsListComponent from "./NewsListComponent";
import NewsEditComponent from "./NewsEditComponent";

export const newsListScreen = {
    id: "newsListScreen",
    type: "custom-component",
    component: NewsListComponent,
    route: {
        path: "/news",
        exact: true
    }
};

export const newsEditConfig = {
    id: "newsEditScreen",
    type: "custom-component",
    component: NewsEditComponent,
    route: {
        path: "/news/edit/:id",
        exact: true
    }
}

export const newsCreateConfig = {
    id: "newsCreateScreen",
    type: "custom-component",
    component: NewsEditComponent,
    route: {
        path: "/news/create",
        exact: true
    }
}