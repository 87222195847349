import {repositoriesTableConfiguration} from "./repositoriesTable.config";
import {loadDataToAssetRepositoryView} from "./loadDataToAssetRepositoryView";
import {BACKEND_URL} from "../../../config";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const repositoriesConfig = {
    id: "assetRepositoriesMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/asset-repositories",
        exact: true
    },
    onInitAction: {
        children: [
            {
                type: "block-component",
                componentId: "assetRepositoriesMain"
            },
            loadDataToAssetRepositoryView
        ]
    },
    children: [
        {
            id: "repositoriesHeader",
            type: "header",
            content: "Asset repositories",

        },
        {
            id: "repositoriesTable",
            type: "table",
            tableConfig: repositoriesTableConfiguration
        },
        {
            id: "assetRepositoriesButtonsRow",
            type: "component",
            grid: {
                role: "row",
            },
            children: [
                {
                    id: "assetRepositoriesButtonsRowCol",
                    type: "component",
                    grid: {
                        role: "col",
                        size: 12
                    },
                    children: [
                        {
                            id: "createRepositoryBtn",
                            type: "button",
                            label: "Add repository",
                            action: {
                                type: "SET_RECORD_TO_COMPONENT",
                                componentId: "repositoryForm",
                                children: [
                                    {
                                        type: "hide-component",
                                        componentId: "repositoriesFormHeaderEdit"
                                    },
                                    {
                                        type: "show-component",
                                        componentId: "repositoriesFormHeaderAdd"
                                    },
                                    {
                                        type: "show-component",
                                        componentId: "repositoryFormMain"
                                    }
                                ]
                            }
                        },
                    ]
                },
            ]
        },


        {
            id: "repositoryFormMain",
            type: "component",
            visibility: "hidden",
            grid: {
                role: "row",
            },
            children: [
                {
                    id: "repositoryFormMainCol",
                    type: "component",
                    grid: {
                        role: "col",
                        size: 12
                    },
                    children: [
                        {
                            id: "repositoriesFormHeaderEdit",
                            type: "header",
                            size: 5,
                            content: "Edit repository",
                            visibility: "hidden"
                        },
                        {
                            id: "repositoriesFormHeaderAdd",
                            type: "header",
                            size: 5,
                            content: "Add repository",
                            visibility: "hidden"
                        },
                        {
                            id: "repositoryForm",
                            type: "form",
                            layout: {
                              direction: "vertical"
                            },
                            fields: [
                                {
                                    label: "Location",
                                    name: "location",
                                    type: "input",
                                    validators: ["required"]
                                },
                                {
                                    label: "Thumbnails location",
                                    name: "thumbnailLocation",
                                    type: "input",
                                    validators: ["required"]
                                }
                            ],
                            submit: {
                                label: "Save"
                            },
                            submitAction: {
                                children: [
                                    {
                                        type: "block-component",
                                        componentId: "assetRepositoriesMain"
                                    },
                                    {
                                        type: "call-rest-post",
                                        url: BACKEND_URL + 'asset-repository/save',
                                        baseDateFromComponent: "repositoryForm",
                                        mergeDataFromForm: "repositoryForm",
                                        successAction: {
                                            type: "hide-component",
                                            componentId: "repositoryFormMain",
                                            children: [loadDataToAssetRepositoryView],
                                        },
                                        errorAction: {
                                            children: [
                                                {
                                                    type: "unblock-component",
                                                    componentId: "assetRepositoriesMain"
                                                }
                                            ]
                                        }
                                    }
                                ]
                            },
                            cancelAction: {
                                children: [
                                    {
                                        type: "hide-component",
                                        componentId: "repositoryFormMain"
                                    },

                                ]
                            }
                        }
                    ]
                }
            ]

        }
    ]
}
