export const personTableConfiguration = {
    id: "personTable",
    columns: [
        {
            id: "name",
            label: "name",
            trimToLength: 40,
            sortableKey: "tokenizedName"
        },
        {
            id: "description",
            label: "description",
            trimToLength: 55
        },
        {
            id: "creator",
            label: "creator",
            renderer: (person) => person['_system'] && person['_system'].creator ? person['_system'].creator : null
        },
        {
            id: "dateCreated",
            label: "dateCreated",
            renderer: (person) => person['_system'] && person['_system'].dateCreated ? person['_system'].dateCreated : null
        },

    ],
    recordIdentifier: (person) => person.id,
    onRowClick: (person) => window.location.href = "/person/edit/" + person.id,
}