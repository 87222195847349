import React from 'react'
import {connect} from "react-redux";
import ArtworkFiltersSidebar from "./ArtworkFiltersSidebarComponent";
import BrowseTableComponent from "../../engine/component/BrowseTableComponent";
import * as actions from "../../actions/browseActions";
import SearchToolbarComponent from "./SearchArtworksComponent";
import PaginateWrapperComponent from "./PaginateWrapperComponent";
import {artworksListTableConfiguration} from "./artworksListTable.config";
import {ARTWORKS} from "./BrowseType";
import {Translation} from "react-i18next";
import {openClosePickupWindow} from "../../actions/filtersActions";
import ArtworksBulkActionsComponent from "./ArtworksBulkActionsComponent";
import ArtworksQueries from "./ArtworksQueries";
import {getUserPermissions} from "../../engine/selectors";
import {artworkFilterAvailable} from "./filtersConfig";
import {withRouter} from "react-router-dom";
import {bulkPrintToFile} from "../../table/bulkActionsDuck";

const mapStateToProps = (state) => {
    return {
        content: state.browse[ARTWORKS].results.content,
        canCreate: getUserPermissions(state, "modules", "artwork").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtworks: (page) => dispatch(actions.setPageAndSearch(page, ARTWORKS)),
        openPickUpWindow: (value) => dispatch(openClosePickupWindow(true, artworkFilterAvailable)),
        bulkPrintToFile: (value) => dispatch(bulkPrintToFile()),
    }
};

class ArtworksList extends React.Component {

    componentDidMount() {
        if (!this.props.content || this.props.content.length == 0) {
            this.props.getArtworks(0);
        }
    }

    goToCreateNew() {
        this.props.history.push("/artwork/create");
    }

    render() {

        return (
            <div className="row justify-content-between">
                <sidebar className="col-md-2 artwork-sidebar">
                    <div>
                        <ArtworksQueries browseType={ARTWORKS}/>
                        <ArtworkFiltersSidebar browseType={ARTWORKS}/>
                    </div>
                </sidebar>
                <main className="col-md-10 content">
                    <div className="row">
                        <h2>Lista dzieł</h2>
                    </div>
                    <SearchToolbarComponent filtersAvailable={artworkFilterAvailable} browseType={ARTWORKS}/>

                    <div className="row d-flex align-items-center">
                        <Translation>{t =>

                            <React.Fragment>
                                <div className="col header-actions-menu">
                                    {this.props.canCreate && <button type="button" className="btn btn-primary"
                                                                     onClick={() => this.goToCreateNew()}>{t("new...")}
                                    </button>}
                                    <button type="button" className="btn btn-outline-primary refresh"
                                            onClick={() => this.props.getArtworks(0)}>
                                        <i className="fas fa-sync-alt"></i></button>
                                    <button type="button" className="btn btn-outline-primary delete"><i
                                        className="fas fa-trash-alt"></i></button>
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={this.props.bulkPrintToFile}
                                    ><i
                                        className="fas fa-print"></i>
                                    </button>
                                </div>

                                <div className="col header-actions-menu">
                                    <ArtworksBulkActionsComponent/>
                                </div>

                                <div className="col col-sm-1 header-actions-menu">
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={() => this.props.openPickUpWindow()}>
                                        <i className="fas fa-filter"></i>
                                    </button>

                                </div>
                            </React.Fragment>
                        }</Translation>
                        <div className="col-md-6 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={ARTWORKS} showCounter={true}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <BrowseTableComponent tableConfig={artworksListTableConfiguration}
                                                  browseType={ARTWORKS}
                                                  records={this.props.content}/>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col">
                        </div>
                        <div className="col-md-4 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={ARTWORKS}/>
                        </div>
                    </div>
                </main>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ArtworksList))

