import {adminSubmenuMenuConfig} from "./adminSubmenuMenuConfig";
import {exportConfig} from "./export.config";
import {repositoriesConfig} from "./asset-repositories/repositories.config";
import {importConfig, importHistoryConfig} from "./import.config";
import {assetsConfig} from "./assets/assetsConfig";
import {dictionaryEntriesConfig} from "./dictionary-entries/dictionaryEntriesConfig";
import {dictionariesConfig} from "./dictionaries/dictionariesConfig";
import {databaseConfig} from "./database/databaseConfig";
import {userEditConfig, userGroupsConfig, userGroupsEditConfig, usersConfig} from "./security/usersConfig";

export const administrationScreen = {
    id: "administrationScreen",
    type: "component",
    cssClass: "dla_ab_1",
    grid: {
        role: "row",
    },
    route: {
        path: "/administration",
        exact: false
    },
    children: [
        {
            id: "administrationScreenMenuColNav",
            type: "component",
            grid: {
                role: "col",
                size: 2,
            },
            cssClass: "dla_ab_2",
            children: [
                adminSubmenuMenuConfig
            ]
        },
        {
            id: "administrationScreenMenuColRight",
            type: "component",
            markArea: true,
            cssClass: "dla_ab_3",
            grid: {
                role: "col",
                // size: 10
            },
            children: [
                usersConfig,
                userEditConfig,
                userGroupsConfig,
                userGroupsEditConfig,
                exportConfig,
                importConfig,
                importHistoryConfig,
                repositoriesConfig,
                assetsConfig,
                dictionaryEntriesConfig,
                dictionariesConfig,
                databaseConfig,
            ]
        }
    ]
};
