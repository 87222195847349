import React from 'react'
import {connect} from "react-redux";
import ArtworksSubSearchSide from "./ArtworksSubSearchSide";
import {Translation} from "react-i18next";
import {openClosePickupWindow} from "../../actions/filtersActions";
import {ARTWORKS} from "./BrowseType";
import {artworkFilterAvailable} from "./filtersConfig";

const mapStateToProps = (state) => {
    return state.browse;
};
const mapDispatchToProps = (dispatch) => {
    return {
        openPickUpWindow: () => dispatch(openClosePickupWindow(true, artworkFilterAvailable)),
    }
};

class ArtworkFiltersSidebar extends React.Component {

    render() {
        return (
            <Translation>{t =>
                <div>
                    <h3>
                        <span>{t("filters")}</span>

                        <button type="button" className="btn btn-outline-primary btn-sm"
                                onClick={() => this.props.openPickUpWindow()}>
                            <i className="fas fa-filter"></i>
                            <span>{t("more")}</span>
                        </button>
                    </h3>
                    <ArtworksSubSearchSide dictionaryId="ownership" filterName="ownership" visibleItems={5}
                                           browseType={ARTWORKS}/>
                    <ArtworksSubSearchSide dictionaryId="locations" filterName="location" visibleItems={5}
                                           browseType={ARTWORKS}/>
                    <ArtworksSubSearchSide dictionaryId="projects" filterName="projects" visibleItems={5}
                                           browseType={ARTWORKS}
                                           emptyOption={{id: "isEmpty", labels: {"pl": "Nie przypisano"}}}/>
                    <ArtworksSubSearchSide dictionaryId="objectTypes" filterName="objectTypes" visibleItems={5}
                                           browseType={ARTWORKS}/>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworkFiltersSidebar)

