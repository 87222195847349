import ImportComponent from "../../components/administration/import/ImportComponent";
import ImportHistoryComponent from "../../components/administration/import/ImportHistoryComponent";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const importConfig = {
    id: "importMain",
    type: "custom-component",
    logical: true,
    component: ImportComponent,
    route: {
        path: "/administration/import",
        exact: true
    }
}

export const importHistoryConfig = {
    id: "importMain",
    type: "custom-component",
    logical: true,
    component: ImportHistoryComponent,
    route: {
        path: "/administration/import-history",
        exact: true
    }
}