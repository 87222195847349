import React from "react";

export const newsListTableConfiguration = {
    id: "newsTableV1",
    manageColumns: false,
    columns: [
        {
            id: "titlePl",
            label: "titlePl",
            default: true,
            renderer: (news) => news.title.pl,
            required: true
        },
        {
            id: "titleEn",
            label: "titleEn",
            default: true,
            renderer: (news) => news.title.en,
            required: true
        },
        {
            id: "lang",
            label: "lang",
            default: true,
            renderer: (news) => news._langVersion ? news._langVersion.labels.pl : null,
            required: true
        },
        {
            id: "datePublished",
            label: "datePublished",
            default: true,
            renderer: (news) => news.datePublished.from.year + "-" + news.datePublished.from.month + "-" + news.datePublished.from.day,
            required: true
        },

    ],
    isSelectable: false,
    recordIdentifier: (news) => news.id,
    onRowClick: (news) => window.location.href = "/news/edit/" + news.id,

}

