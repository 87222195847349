//visibility: visible (default), hidden, dynamic
//default component visibility is visible


import {BACKEND_URL} from "../../../config";
import {Translation} from "react-i18next";
import React from "react";

export const dictionariesConfig = {
    id: "dictionariesMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/dictionaries/dictionary-types",
        exact: true
    },
    onInitAction: {
        children: [
            {
                type: "block-component",
                componentId: "dictionariesMain"
            },
            {
                type: "call-rest",
                url: BACKEND_URL + 'dictionary/',
                setResponseToComponent: "dictionariesTable",
                successAction: {
                    children: [
                        {
                            type: "unblock-component",
                            componentId: "dictionariesMain"
                        }
                    ]
                },
                errorAction: {
                    children: [
                        {
                            type: "unblock-component",
                            componentId: "dictionariesMain"
                        }
                    ]
                }
            }
        ]
    },
    children: [
        {
            id: "dictionariesMainHeader",
            type: "header",
            content: "Dictionaries",

        },
        {
            id: "dictionariesTable",
            type: "table",
            tableConfig: {
                id: "dictionariesTableV1",
                columns: [
                    {
                        id: "id",
                        label: "id",
                        renderer: (dictionary) => <Translation>{t => t("dictionary." + dictionary.id)}</Translation>
                    }
                ],
                recordIdentifier: (entry) => entry.id,
                onRowClick: {
                    type: "set-search-form-value",
                    componentId: "dictionaryEntriesTable",
                    name: "dictionary",
                    mapping: "id"
                }
            }
        }
    ]
}
