import React from 'react'
import {connect} from "react-redux";

import {addRemoveUserGroup, getUserGroups, loadUser, saveUser} from "./usersDuck";

const mapStateToProps = (state, ownProps) => {
    return {
        user: state.users.user,
        userGroups: state.users.userGroups,
        userId: ownProps.match.params.userId,
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUser: () => dispatch(loadUser(ownProps.match.params.userId)),
        getUserGroups: () => dispatch(getUserGroups()),
        addRemoveUserGroup: (ug, checked) => dispatch(addRemoveUserGroup(ug, checked)),
        saveUser: () => dispatch(saveUser()),
    }
};

class UserProfileComponent extends React.Component {

    componentDidMount() {
        if (!this.props.user || this.props.user.id != this.props.userId) {
            this.props.getUser();
        }

        this.props.getUserGroups();
    }

    createNewArtwork() {
        window.location.href = "/artwork/create";
    }

    isUserGroupSelected(ug) {
        return this.props.user.userGroups.find(value => value.id == ug.id) != undefined
    }


    render() {

        if (!this.props.user) {

            return null;
        }

        let userGrups = this.props.userGroups.map(ug =>
            (<tr>
                <td>
                    <input type="checkbox" checked={this.isUserGroupSelected(ug)}
                           onChange={(e) => this.props.addRemoveUserGroup(ug, e.target.checked)}
                    />

                </td>
                <td>{ug.name.pl}</td>
                <td>{ug.description.pl}</td>
                <td>{ug.mainRole}</td>
            </tr>)
        )


        return (
            <div className="row">
                <div className="col">
                    <h1>Profil użytkownika: {this.props.user.id}</h1>


                    <table>
                        {userGrups}

                    </table>

                    <button type="button" className="btn btn-primary"
                            onClick={event => this.props.saveUser()}>Zapisz
                    </button>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfileComponent)

