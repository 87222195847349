import React from 'react'
import {connect} from "react-redux";

import {getUserGroups} from "./usersDuck";
import BrowseTableComponent from "../../../engine/component/BrowseTableComponent";
import {usersGroupsListTableConfiguration} from "./userGroupsListTable.config";

const mapStateToProps = (state) => {
    return {
        userGroups: state.users.userGroups,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtworks: (page) => dispatch(getUserGroups()),
        // openPickUpWindow: (value) => dispatch(openClosePickupWindow(true)),
    }
};

class UsersListComponent extends React.Component {

    componentDidMount() {
        this.props.getArtworks(0);
    }

    createNewArtwork() {
        window.location.href = "/artwork/create";
    }

    render() {

        return (
            <div className="row">
                <div className="col">
                    <BrowseTableComponent tableConfig={usersGroupsListTableConfiguration}
                                          records={this.props.userGroups}/>
                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersListComponent)

