import UsersListComponent from "./UsersListComponent";
import UserProfileComponent from "./UserProfileComponent";
import UserGroupsListComponent from "./UserGroupsListComponent";
import UserGroupEditComponent from "./UserGroupEditComponent";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const usersConfig = {
    id: "usersListScreen",
    type: "custom-component",
    component: UsersListComponent,
    route: {
        path: "/administration/user/list",
        exact: true
    }
}

export const userEditConfig = {
    id: "userEditScreen",
    type: "custom-component",
    component: UserProfileComponent,
    route: {
        path: "/administration/user/edit/:userId",
        exact: false
    }
}

export const userGroupsConfig = {
    id: "userGroupsListScreen",
    type: "custom-component",
    component: UserGroupsListComponent,
    route: {
        path: "/administration/user-groups/list",
        exact: true
    }
}


export const userGroupsEditConfig = {
    id: "userGroupsEditScreen",
    type: "custom-component",
    component: UserGroupEditComponent,
    route: {
        path: "/administration/user-groups/edit/:id",
        exact: false
    }
}
