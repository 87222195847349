import React from 'react'
import * as actions from "../../actions/artworkActions";
import {ARTWORK_VALIDATE_INVENTORY_NUMBER, ARTWORK_VALIDATE_TITLE} from "../../actions/artworkActions";
import {connect} from "react-redux";
import SimpleEditableField from "./SimpleEditableField";
import SelectizeEditableField from "./SelectizeEditableField";
import OtherAuthorsEditableField from "./OtherAuthorsEditableField";
import AuthorsEditableField from "./AuthorsEditableField";
import MaterialTechniqueEditableField from "./MaterialTechniqueEditableField";
import DescriptionEditableField from "./DescriptionEditableField";
import CardPrepartionEditableField from "./CardPrepartionEditableField";
import PlaceOfCreationEditableField from "./PlaceOfCreationEditableField";
import DateOfCreationEditableField from "./DateOfCreationEditableField";
import ArtworkPreviews from "./ArtworkPreviews";
import VisibleInNavigartEditableField from "./VisibleInNavigartEditableField";
import FoldersEditableField from "./FoldersEditableField";
import ArtworkChildren from "./ArtworkChildren";
import ArtworkParent from "./ArtworkParent";
import {Translation} from "react-i18next";
import UserActivity from "../../tracking/UserActivity";
import CropComponent from "../crop/CropComponent";
import {getUserPermissions} from "../../engine/selectors";
import {withRouter} from "react-router-dom";
import {ARTWORKS} from "../browse/BrowseType";
import NavigateInSearchResultsComponent from "../browse/NavigateInSearchResultsComponent";

const mapStateToProps = (state, ownProps) => {
    return {
        cloneInventoryNumber: ownProps.match.params.cloneInventoryNumber,
        artworkId: ownProps.match.params.inventoryNumber,
        parentArtworkId: ownProps.match.params.parentArtworkId,
        artwork: state.artworks.artwork,
        canCreate: getUserPermissions(state, "modules", "artwork").DOCS_CREATE
    }
        ;
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtwork: (artworkId,clone) => dispatch(actions.getArtworkDetails(artworkId,clone)),
        getArtworkChildren: (artworkId) => dispatch(actions.getArtworkChildren(artworkId)),
        setArtworkToEdit: (artwork) => dispatch(actions.setArtworkToEdit(artwork)),
        startUpdate: (artwork) => dispatch(actions.startUpdate(artwork))
    }
};

class ItemDetails extends React.Component {

    componentWillMount() {
        this.prepare();
    }

    componentDidUpdate() {
        this.prepare();
    }

    prepare() {
        if (!this.props.artwork || this.props.artwork.id != this.props.artworkId) {

            if (this.props.artworkId) {
                this.props.getArtwork(this.props.artworkId, false);
                this.props.getArtworkChildren(this.props.artworkId);
            } else if (this.props.cloneInventoryNumber) {
                this.props.getArtwork(this.props.cloneInventoryNumber, true);
            } else {

                let newArtwork = {
                    description: {
                        references: [],
                        tags: [],
                    },
                    materialTechnique: {
                        materials: [],
                        techniques: [],
                        filigran: [],
                    }
                };
                if (this.props.parentArtworkId) {
                    newArtwork._parentObject = {
                        ref: "artwork",
                        id: this.props.parentArtworkId
                    }
                }

                this.props.setArtworkToEdit(newArtwork);
            }
        }
    }


    updateArtwork() {
        this.props.startUpdate(this.props.artwork);
    }

    goBack() {
        this.props.history.push("/artworks/");
    }

    goToClone() {
        this.props.history.push("/artwork/clone/" + this.props.artwork.id);
    }


    goToCreateChild() {
        this.props.history.push("/artwork/create-child/" + this.props.artwork.id);
    }

    render() {
        if (!this.props.artwork || !this.props.canCreate) {
            return (<div></div>);
        }


        let artworkPreview = null;
        let additionalActionsBar = null;
        if (this.props.artwork.id && !this.props.artwork.deleted) {
            artworkPreview = (<ArtworkPreviews type={ARTWORKS}/>)
            additionalActionsBar = (<React.Fragment>
                <button type="button" className="btn btn-outline-primary duplicate"
                        title="Duplicate"
                        onClick={event => this.goToClone()}>
                    <i className="fas fa-paste"></i>
                </button>
                <button type="button" className="btn btn-outline-primary createChild" title="Create child object"
                        onClick={event => this.goToCreateChild()}>
                    <i className="fas fa-sitemap"></i>
                </button>
            </React.Fragment>)
        } else if (!this.props.artwork.deleted) {
            artworkPreview = (<Translation>{t => <div className="alert alert-secondary" role="alert">
                {t("saveNewArtworkThenUploadImages")}
            </div>}</Translation>);
            additionalActionsBar = (<Translation>{t => <div className="alert alert-secondary inline" role="alert">
                {t("documentIsNotSaved")}
            </div>}</Translation>);
        } else {
            additionalActionsBar = (<Translation>{t => <div className="alert alert-danger inline" role="alert">
                {t("documentIsDeleted")}
            </div>}</Translation>);
        }


        return (
            <div className="row">
                <div className="col-md-2 item-details-left-col">
                    {artworkPreview}
                    <ArtworkParent artwork={this.props.artwork}/>
                    < ArtworkChildren/>
                </div>
                <main className="col-md-10 content">
                    <div className="row">
                        <CropComponent/>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={() => this.goBack()}><i
                                className="fas fa-angle-left"></i></button>
                            <button type="button" className="btn btn-primary" disabled={this.props.artwork.deleted}
                                    onClick={event => this.updateArtwork()}>Zapisz
                            </button>
                            {additionalActionsBar}

                        </div>
                        <div className="col header-actions-menu">
                            <VisibleInNavigartEditableField/>
                        </div>
                        <div className="col header-actions-menu">
                            <NavigateInSearchResultsComponent browseType={ARTWORKS} recordId={this.props.artwork.id}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-5">
                            <SimpleEditableField path="inventoryNumber" type="input"
                                                 validator={ARTWORK_VALIDATE_INVENTORY_NUMBER}/>
                            <FoldersEditableField/>
                            <SimpleEditableField path="title" validator={ARTWORK_VALIDATE_TITLE} type="input"/>
                            <OtherAuthorsEditableField/>
                            <DateOfCreationEditableField/>
                            <SimpleEditableField path="inscription.inscription"/>
                            <MaterialTechniqueEditableField/>
                            <DescriptionEditableField/>
                            <SelectizeEditableField path="location" dictionaryId="locations"/>
                            <CardPrepartionEditableField/>
                        </div>
                        <div className="col-xl-5">
                            <SimpleEditableField path="storage" type="input"/>
                            <AuthorsEditableField/>
                            <SelectizeEditableField path="objectTypes"/>
                            <PlaceOfCreationEditableField/>
                            <SimpleEditableField path="dimensions" type="input"/>
                            <SelectizeEditableField path="provenance" hideSelectArrow={true}/>
                            <SimpleEditableField path="copyright" type="input"/>
                            <SelectizeEditableField path="ownership"/>
                            <SelectizeEditableField path="iconclass" hideSelectArrow={true}/>
                            <SelectizeEditableField path="projects"/>
                        </div>
                    </div>
                    <UserActivity/>
                </main>
            </div>
        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ItemDetails))