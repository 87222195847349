import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "../../components/item/ToggleEditable";
import SimpleEditableFieldInline from "./SimpleEditableFieldInline";
import SelectizeEditableFieldInline from "./SelectizeEditableFieldInline";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "correspondenceAddress"),
    }
};
const mapDispatchToProps = (dispatch) => {
    return {}
};


class CorrespondenceAddressEditableField extends React.Component {
    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("correspondenceAddress")}</span>
                        <ToggleEditable fieldId={"correspondenceAddress"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <SimpleEditableFieldInline path="correspondenceAddress.street" label="street" type="input"
                                                   enabledOverride={this.props.enabled}/>
                        <SimpleEditableFieldInline path="correspondenceAddress.postalCode" label="postalCode"
                                                   type="input" enabledOverride={this.props.enabled}/>
                        <SelectizeEditableFieldInline path="correspondenceAddress.city" label="city"
                                                      dictionaryId="cities"
                                                      enabledOverride={this.props.enabled} dictionaryId="members_city"/>
                        <SelectizeEditableFieldInline path="correspondenceAddress.country" label="country"
                                                      dictionaryId="countries"
                                                      enabledOverride={this.props.enabled}
                                                      dictionaryId="members_country"/>
                    </div>

                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CorrespondenceAddressEditableField)

