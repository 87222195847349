//visibility: visible (default), hidden, dynamic
//default component visibility is visible


import React from "react";
import {mainMenuConfig} from "./mainMenuConfig";
import {administrationScreen} from "./admin/administrationScreen";
import {personsCreateConfig, personsEditConfig, personsScreen} from "./personsScreen";
import {
    dictionaryEntriesCreateScreen,
    dictionaryEntriesEditScreen,
    dictionaryEntriesScreen
} from "./dictionaryEntriesScreen";
import {artworksListScreen} from "./artworksListScreen";
import {
    artworkCloneScreen,
    artworkCreateChildScreen,
    artworkCreateScreen,
    artworkEditScreen
} from "./artworkEditScreen";
import {loginScreen} from "./admin/loginScreen";
import {newsCreateConfig, newsEditConfig, newsListScreen} from "../news/newsConfig";
import {contentCreateConfig, contentEditConfig, contentListScreen} from "../content/contentConfig";
import {membersCreateScreen, membersEditScreen, membersListScreen} from "../members/memberListScreen";

export const mainConfig = {
    id: "mainViewport",
    type: "component",
    grid: {
        role: "container"
    },
    children: [
        {
            id: "headerRow",
            type: "component",
            grid: {
                role: "row"
            },
            children: [
                {
                    id: "headerRowCol",
                    type: "component",
                    grid: {
                        role: "col"
                    },
                    children: [
                        mainMenuConfig
                    ]
                }
            ]
        },
        artworksListScreen,
        artworkEditScreen,
        artworkCreateScreen,
        artworkCreateChildScreen,
        artworkCloneScreen,
        newsListScreen,
        newsEditConfig,
        newsCreateConfig,
        contentListScreen,
        contentEditConfig,
        contentCreateConfig,
        dictionaryEntriesScreen,
        dictionaryEntriesEditScreen,
        dictionaryEntriesCreateScreen,
        personsScreen,
        personsEditConfig,
        personsCreateConfig,
        administrationScreen,
        loginScreen,

        membersListScreen,
        membersCreateScreen,
        membersEditScreen
    ]
}

