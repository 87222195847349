import PersonsList from "../components/person/PersonsListComponent";
import PersonEditComponent from "../components/person/PersonEditComponent";

export const personsScreen = {
    id: "personsScreen",
    type: "custom-component",
    component: PersonsList,
    route: {
        path: "/persons",
        exact: false
    }
};

export const personsEditConfig = {
    id: "personEditScreen",
    type: "custom-component",
    component: PersonEditComponent,
    route: {
        path: "/person/edit/:id",
        exact: true
    }
}

export const personsCreateConfig = {
    id: "personCreateScreen",
    type: "custom-component",
    component: PersonEditComponent,
    route: {
        path: "/person/create",
        exact: true
    }
}