import React from 'react'
import {Translation} from "react-i18next";

export const translateRenderer = (slug) => <Translation>{t => t(slug)}</Translation>

export const importResultsTableConfiguration = {
    id: "importResultsTableV1",
    columns: [
        {
            id: "filename",
            label: "filename",
        },
        {
            id: "artworkId",
            label: "artworkId",
        },
        {
            id: "inventoryNumber",
            label: "inventoryNumber",
        },
        {
            id: "importStatus",
            label: "importStatus",
            renderer: (r) => translateRenderer(r.status),
        },
    ],
    recordIdentifier: (entry) => entry.id,
    rowRendererHelper: (r) => {
        return {"warning-row": r.status != "OK"}
    }
}

