import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import {filterMenuItem} from "./NavBar";
import {Translation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        config: state.engine.configsMap[ownProps.configId],
        userPermissions: state.engine.components.loggedUserComponent ? state.engine.components.loggedUserComponent.data.permissions : {},
    };
};
const mapDispatchToProps = (dispatch) => {
    return {}
};

class AdministrationSubmenu extends React.Component {
    render() {
        let items = this.props.config.items
            .filter(item => filterMenuItem(item.requiredPermission, this.props.userPermissions))
            .map(config => {

                let children = null;

                if (config.children) {
                    let subItems = config.children
                        .filter(item => filterMenuItem(item.requiredPermission, this.props.userPermissions))
                        .map(subConfig => {
                            return <li className="list-group-item noborder"><Link key={subConfig.url}
                                                                                  to={"/" + subConfig.url}>

                                <Translation>{t => t(subConfig.label)}</Translation>
                            </Link>
                            </li>
                        })


                    children = (
                        <ul className="list-group">
                            {subItems}
                        </ul>
                    )

            }

            let main = null;

            if (config.url) {
                main = <Link to={"/" + config.url}><Translation>{t => t(config.label)}</Translation></Link>
            }
            else {
                main = <span><Translation>{t => t(config.label)}</Translation></span>
            }






            return (<li className="list-group-item" key={config.url}>
                {main}
                {children}

            </li>);
        });

        return (
            <ul className="list-group">
            {items}
            </ul>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdministrationSubmenu)

