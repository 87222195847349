import {dictionaryEntryTableConfiguration} from "../../../components/dictionary-entry/dictionaryEntryTable.config";
import {BACKEND_URL} from "../../../config";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const dictionaryEntriesConfig = {
    id: "dictionaryENtriesMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/dictionaries/entries",
        exact: true
    },
    children: [
        {
            id: "dictionaryEntriesHeader",
            type: "header",
            content: "Dictionary Entries ",

        },
        {
            id: "dictionaryEntriesTable",
            type: "ajax-table",
            tableConfig: dictionaryEntryTableConfiguration,
            searchForm: {
                fields: [
                    {
                        label: "Name",
                        placeholder: "Name",
                        name: "name",
                        type: "input",
                    },
                    {
                        label: "Dictionary",
                        placeholder: "Dictionary",
                        name: "dictionary",
                        type: "input",
                    }
                ]
            },
            dataSource: {
                type: "ajax",
                method: "POST",
                url: BACKEND_URL + 'dictionary-entries/search',
            }
        },
    ]
}
