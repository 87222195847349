import React from 'react'
import {connect} from "react-redux";
import ArtworkChild from "./ArtworkChild";
import {Translation} from "react-i18next";

const mapStateToProps = (state, ownProps) => {
    return {
        children: state.artworks.children ? state.artworks.children : [],
        artworkId: state.artworks.artwork.id
    }
    ;
};
const mapDispatchToProps = (dispatch) => {
    return {

    };
};

class ArtworkChildren extends React.Component {


    render() {
        let children = this.props.children.map(artwork => <ArtworkChild key={artwork.id} artwork={artwork}
                                                                        parentArtworkId={this.props.artworkId}/>);

        if (this.props.children && this.props.children.length > 0) {
            return (


                <div className="children-list-component">
                    <Translation>{t =>
                        <h6>{t("childrenArtworks")}
                            <sup>&nbsp;<span className="badge badge-info">{children.length}</span></sup>
                        </h6>
                    }</Translation>

                    <div>
                        {children}
                    </div>

                </div>
            )
        }
        return null;
    }

}


export default connect(mapStateToProps, mapDispatchToProps)(ArtworkChildren)