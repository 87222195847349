import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {getContent, saveContent, setContent, setLangVersion, setTitle} from "./contentDuck";
import Select from "react-select";
import ContentEditLanguage from "./ContentEditLanguageComponent";
import {commonDataLoadDictionaryEntries, selectCommonDataDictionaryEntries} from "../common/commonDuck";
import {getUserPermissions} from "../engine/selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        content: state.content.content,
        dictionaryEntries: selectCommonDataDictionaryEntries(state, "languageVersions").map(mapDictionaryEntry),
        canCreate: getUserPermissions(state, "content", "news").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getContent: () => dispatch(getContent(ownProps.match.params.id)),
        loadLanguageVersions: () => dispatch(commonDataLoadDictionaryEntries("languageVersions")),
        setLangVersion: (dictionaryEntry) => dispatch(setLangVersion(dictionaryEntry)),
        setTitle: (lang, value) => dispatch(setTitle(lang, value)),
        setContent: (lang, value) => dispatch(setContent(lang, value)),
        saveContent: () => dispatch(saveContent()),
    }
};

class ContentEdit extends React.Component {

    componentDidMount() {
        this.props.getContent();
        this.props.loadLanguageVersions();
    }

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setLangVersion(inputValue.value);
            default:
                break;
        }

    };

    handleEditorChange = (lang, content) => {
        this.props.setContent(lang, content)
    };

    handleTitleEditorChange = (lang, content) => {
        this.props.setTitle(lang, content)
    };

    goBack() {
        window.location.href = "/content/";
    }


    render() {
        if (!this.props.content || !this.props.canCreate) {
            return null
        }


        let headerName = this.props.id ? "contentEdit" : "contentCreate"

        let valid = (this.props.content.title.pl != "" || this.props.content.title.en != "") && this.props.content._langVersion

        return (
            <div className="row justify-content-between">
                <main className="col-md-12 content">
                    <div className="row">
                        <Translation>{t =>
                            <h2>{t(headerName)}</h2>
                        }</Translation>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={this.goBack}><i
                                className="fas fa-angle-left"></i></button>
                            <button type="button" className="btn btn-primary" disabled={!valid}
                                    onClick={event => this.props.saveContent()}>Zapisz
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5">

                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("languageVersions")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content">
                                    <Select
                                        name="visibleInNavigart"
                                        value={this.props.content._langVersion ? mapDictionaryEntry(this.props.content._langVersion) : null}
                                        onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                                        isClearable={false}
                                        defaultOptions
                                        options={this.props.dictionaryEntries}
                                    />
                                </div>
                            </div>
                        </div>


                    </div>

                    <div className="row">
                        <ContentEditLanguage lang="pl"/>
                        <ContentEditLanguage lang="en"/>
                    </div>

                </main>
            </div>

        )
    }
}

function mapDictionaryEntry(entry) {
    return {
        label: entry.labels.pl,
        value: entry
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentEdit)

