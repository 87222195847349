import {RECORD_TYPES} from "../config";

export const mainMenuConfig = {
    type: "navbar",
    items: RECORD_TYPES == "ARTWORKS" ? [
        {
            url: "artworks",
            label: "dzieło",
            requiredPermission: ["modules", "artwork", "READ"],
        },
        {
            url: "persons",
            label: "osoba",
            requiredPermission: ["modules", "person", "READ"],
        },
        {
            url: "content",
            label: "treść",
            requiredPermission: ["modules", "content", "READ"],
        },
        {
            url: "news",
            label: "aktualności",
            requiredPermission: ["modules", "news", "READ"],
        },
        {
            url: "dictionary-entry",
            label: "słowniki",
            requiredPermission: ["modules", "dictionary", "READ"],
        },
        {
            url: "administration",
            label: "administracja",
            requiredPermission: ["modules", "admin", "READ"],
        },
    ] : RECORD_TYPES == "MEMBERS" ? [
        {
            url: "members",
            label: "Członkowie",
            requiredPermission: ["modules", "artwork", "READ"],
        },
    ] : []
};