import React from 'react'
import {connect} from "react-redux";
import {ARTWORKS} from "./BrowseType";
import {getAllSelected, SELECTION_STATE} from "../../table/selectionDuck";
import {
    BULK_OPERATION_TYPE_ADD_REMOVE,
    bulkAddRemoveFolder,
    bulkAddRemoveProject,
    bulkAddRemoveToNavigart,
    getFoldersForBulkActions,
    getProjectsForBulkActions,
    prepareDataForComponent
} from "../../table/bulkActionsDuck";

const mapStateToProps = (state) => {
    return {
        content: state.browse[ARTWORKS].results.content,
        enabled: getAllSelected(state) !== SELECTION_STATE.NONE,
        projects: getProjectsForBulkActions(state),
        folders: getFoldersForBulkActions(state),
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        bulkAddToNavigart: () => dispatch(bulkAddRemoveToNavigart(BULK_OPERATION_TYPE_ADD_REMOVE.ADD)),
        bulkRemoveFromNavigart: () => dispatch(bulkAddRemoveToNavigart(BULK_OPERATION_TYPE_ADD_REMOVE.REMOVE)),
        bulkAddProject: (projectId) => dispatch(bulkAddRemoveProject(projectId, BULK_OPERATION_TYPE_ADD_REMOVE.ADD)),
        bulkRemoveProject: (projectId) => dispatch(bulkAddRemoveProject(projectId, BULK_OPERATION_TYPE_ADD_REMOVE.REMOVE)),
        bulkAddFolder: (folderId) => dispatch(bulkAddRemoveFolder(folderId, BULK_OPERATION_TYPE_ADD_REMOVE.ADD)),
        bulkRemoveFolder: (folderId) => dispatch(bulkAddRemoveFolder(folderId, BULK_OPERATION_TYPE_ADD_REMOVE.REMOVE)),
        prepareDataForComponent: () => dispatch(prepareDataForComponent()),
    }
};

const getFolderLabel = f => f.labels && f.labels.pl ? f.labels.pl : f.id

class ArtworksBulkActionsComponent extends React.Component {

    componentDidMount() {
        this.props.prepareDataForComponent();
    }

    render() {

        let folders = this.props.folders.filter((f) => f.folderType == "LABEL" && f._parentFolder);

        let foldersAdd = folders.map(f => {
            return <a className="dropdown-item" href="#"
                      key={f.id}
                      onClick={() => this.props.bulkAddFolder(f.id)}>{getFolderLabel(f)}</a>
        })

        let foldersRemove = folders.map(f => {
            return <a className="dropdown-item" href="#"
                      key={f.id}
                      onClick={() => this.props.bulkRemoveFolder(f.id)}>{getFolderLabel(f)}</a>
        })


        let projectsAdd = this.props.projects.map(project => {
            return <a className="dropdown-item" href="#"
                      key={project.id}
                      onClick={() => this.props.bulkAddProject(project.id)}>{getFolderLabel(project)}</a>
        })

        let projectsRemove = this.props.projects.map(project => {
            return <a className="dropdown-item" href="#"
                      key={project.id}
                      onClick={() => this.props.bulkRemoveProject(project.id)}>{getFolderLabel(project)}</a>
        })


        return (
            <React.Fragment>
                <div className={`btn-group ${this.props.enabled ? "dropdown-enabled" : "dropdown-disabled"}`}>
                    <button type="button"
                            className={`btn dropdown-toggle hover-dropdown ${this.props.enabled ? "btn-success" : "btn-secondary"}`}
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        Dodaj...
                    </button>

                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Collections</a>
                            <ul className="dropdown-menu">
                                {foldersAdd}
                            </ul>
                        </li>

                        <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Project</a>
                            <ul className="dropdown-menu">
                                {projectsAdd}
                            </ul>
                        </li>

                        <li><a className="dropdown-item" href="#"
                               onClick={() => this.props.bulkAddToNavigart()}>Navigart</a></li>
                    </ul>
                </div>

                <div className={`btn-group ${this.props.enabled ? "dropdown-enabled" : "dropdown-disabled"}`}>
                    <button type="button"
                            className={`btn dropdown-toggle hover-dropdown ${this.props.enabled ? "btn-warning" : "btn-secondary"}`}
                            data-toggle="dropdown"
                            aria-haspopup="true" aria-expanded="false">
                        Usuń...
                    </button>

                    <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">

                        {/*<li><a className="dropdown-item" href="#">Another action</a></li>*/}

                        <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Collections</a>
                            <ul className="dropdown-menu">
                                {foldersRemove}
                            </ul>
                        </li>


                        <li className="dropdown-submenu">
                            <a className="dropdown-item dropdown-toggle" data-toggle="dropdown" href="#">Project</a>
                            <ul className="dropdown-menu">
                                {projectsRemove}
                            </ul>
                        </li>

                        <li><a className="dropdown-item" href="#"
                               onClick={() => this.props.bulkRemoveFromNavigart()}>Navigart</a></li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArtworksBulkActionsComponent)

