export const adminSubmenuMenuConfig = {
    id: "adminsubmenu",
    type: "subnavbar",
    route: {
        path: "/administration",
        exact: false
    },
    items: [
        {
            label: "Security",
            children: [
                {
                    url: "administration/user/list",
                    label: "Users",
                    requiredPermission: ["modules", "user", "READ"],
                },
                {
                    url: "administration/user-groups/list",
                    label: "User Groups",
                    requiredPermission: ["modules", "user_group", "READ"],
                },
            ]
        },
        {
            label: "Dictionaries",
            children: [
                {
                    url: "dictionary-entry",
                    label: "Entries",
                    requiredPermission: ["modules", "dictionary", "READ"],
                },
                {
                    url: "administration/dictionaries/dictionary-types",
                    label: "Dictionaries",
                    requiredPermission: ["modules", "dictionary_entry", "READ"],
                }
            ]
        },
        {
            label: "Assets",
            requiredPermission: ["modules", "asset_admin", "READ"],
            children: [
                {
                    url: "administration/assets",
                    label: "Assets"
                },
                {
                    url: "administration/asset-repositories",
                    label: "Repositories"
                }
            ]
        },
        {
            url: "administration/navigart",
            label: "Navigart",
            requiredPermission: ["modules", "navigart", "READ"],
        },
        {
            url: "administration/folders",
            label: "Folders",
            requiredPermission: ["modules", "folder", "READ"],
        },
        {
            label: "Import",
            requiredPermission: ["modules", "import", "READ"],
            children: [
                {
                    url: "administration/import",
                    label: "Import"
                },
                {
                    url: "administration/import-history",
                    label: "History"
                }
            ]
        },
        {
            url: "administration/database",
            label: "Database",
            requiredPermission: ["modules", "database", "READ"],
        }
    ]
};