import {exportResultsTableConfiguration} from "./exportResultsTable.config";
import {BACKEND_URL} from "../../config";

//visibility: visible (default), hidden, dynamic
//default component visibility is visible


export const exportConfig = {
    id: "exportToNavigartMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/navigart",
        exact: true
    },
    children: [
        {
            id: "exportHeader",
            type: "header",
            content: "Export to navigart",

        },
        {
            id: "exportAllBtn",
            type: "button",
            label: "Incremental export",
            action: {
                children: [
                    {
                        type: "hide-component",
                        componentId: "fullExportConfirmation"
                    },
                    {
                        type: "block-component",
                        componentId: "exportToNavigartMain"
                    },
                    {
                        type: "clean-data-in-component",
                        componentId: "exportResultsTable"
                    },
                    {
                        type: "call-rest",
                        url: BACKEND_URL + 'elastic/export',
                        setResponseToComponent: "exportResultsTable",
                        successAction: {
                            children: [
                                {
                                    type: "unblock-component",
                                    componentId: "exportToNavigartMain"
                                },
                                {
                                    type: "show-component",
                                    componentId: "exportResults"
                                },
                            ]
                        },
                        errorAction: {
                            children: [
                                {
                                    type: "unblock-component",
                                    componentId: "exportToNavigartMain"
                                }
                            ]
                        }
                    }
                ]
            }
        },
        {
            id: "exportFullBtn",
            type: "button",
            label: "Full export",
            action: {
                children: [
                    {
                        type: "show-component",
                        componentId: "fullExportConfirmation"
                    }
                ]
            }
        },
        {
            id: "fullExportConfirmation",
            type: "component",
            visibility: "hidden",
            children: [
                {
                    id: "fullExportConfirmationMessage",
                    type: "message",
                    content: "This action will remove all artworks from Navigart, then export all of them again. It may take up to few hours.",
                },
                {
                    id: "fullExportConfirmationButton",
                    type: "button",
                    label: "Start full export process",
                    action: {
                        children: [
                            {
                                type: "hide-component",
                                componentId: "fullExportConfirmation"
                            },
                            {
                                type: "hide-component",
                                componentId: "exportResults"
                            },
                            {
                                type: "clean-data-in-component",
                                componentId: "exportResultsTable"
                            },
                            {
                                type: "block-component",
                                componentId: "exportToNavigartMain"
                            },
                            {
                                type: "call-rest",
                                url: BACKEND_URL + 'elastic/rebuild',
                                setResponseToComponent: "exportResultsTable",
                                successAction: {
                                    children: [
                                        {
                                            type: "unblock-component",
                                            componentId: "exportToNavigartMain"
                                        },
                                        {
                                            type: "show-component",
                                            componentId: "exportResults"
                                        },
                                    ]
                                },
                                errorAction: {
                                    children: [
                                        {
                                            type: "unblock-component",
                                            componentId: "exportToNavigartMain"
                                        }
                                    ]
                                }
                            }
                        ]
                    }
                },
                {
                    id: "fullExportCancelButton",
                    type: "button",
                    label: "Cancel",
                    action: {
                        children: [
                            {
                                type: "hide-component",
                                componentId: "fullExportConfirmation"
                            }
                        ]
                    }

                }
            ]
        },
        {
            id: "exportResults",
            type: "component",
            visibility: "hidden",
            children: [
                {
                    id: "exportResultsHeader",
                    type: "header",
                    content: "Export results",
                },
                {
                    id: "exportResultsTable",
                    type: "table",
                    tableConfig: exportResultsTableConfiguration
                },
            ]

        },
    ]
}