import React from 'react'
import {connect} from "react-redux";
import {setAttributeSecondLevel, setCardPreparationDate} from "../../actions/artworkActions";
import {Translation} from "react-i18next";
import ToggleEditable from "./ToggleEditable";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";


const getDatePartFromState = (artwork, dateType, yearMonthDay) => artwork.cardPreparation
&& artwork.cardPreparation[dateType]
&& artwork.cardPreparation[dateType].from
    ? artwork.cardPreparation[dateType].from[yearMonthDay] : '';

const mapStateToProps = (state, ownProps) => {
    return {
        enabled: selectFieldEditEnabled(state, "cardPreparation"),
        approver: state.artworks.artwork.cardPreparation ? state.artworks.artwork.cardPreparation.approver : '',
        cardAuthor: state.artworks.artwork.cardPreparation ? state.artworks.artwork.cardPreparation.cardAuthor : '',
        approvedDateYear: getDatePartFromState(state.artworks.artwork, "approvedDate", "year"),
        approvedDateMonth: getDatePartFromState(state.artworks.artwork, "approvedDate", "month"),
        approvedDateDay: getDatePartFromState(state.artworks.artwork, "approvedDate", "day"),
        cardAuthoredDateYear: getDatePartFromState(state.artworks.artwork, "cardAuthoredDate", "year"),
        cardAuthoredDateMonth: getDatePartFromState(state.artworks.artwork, "cardAuthoredDate", "month"),
        cardAuthoredDateDay: getDatePartFromState(state.artworks.artwork, "cardAuthoredDate", "day")
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setApprover: (value) => dispatch(setAttributeSecondLevel("cardPreparation", "approver", value)),
        setCardAuthor: (value) => dispatch(setAttributeSecondLevel("cardPreparation", "cardAuthor", value)),
        setApprovedDate: (yearMonthDay, value) => dispatch(setCardPreparationDate("approvedDate", yearMonthDay, value)),
        setCardAuthoredDate: (yearMonthDay, value) => dispatch(setCardPreparationDate("cardAuthoredDate", yearMonthDay, value)),
    }
};

class CardPrepartionEditableField extends React.Component {
    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("cardPreparation")}</span>
                        <ToggleEditable fieldId={"cardPreparation"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("cardPreparation.approver")}</div>
                            <div className="col">

                                <input type="text" value={this.props.approver} name="approver"
                                       disabled={!this.props.enabled}
                                       onChange={(e) => this.props.setApprover(e.target.value)}/>
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("cardPreparation.approveDate")}</div>
                            <div className="col">
                                <input placeholder="Rok" type="number" name="approvedDateYear"
                                       disabled={!this.props.enabled}
                                       value={this.props.approvedDateYear}
                                       onChange={(e) => this.props.setApprovedDate("year", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Miesiąc" type="number" name="approvedDateMonth"
                                       disabled={!this.props.enabled}
                                       value={this.props.approvedDateMonth}
                                       onChange={(e) => this.props.setApprovedDate("month", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Dzień" type="number" name="approvedDateDay"
                                       disabled={!this.props.enabled}
                                       value={this.props.approvedDateDay}
                                       onChange={(e) => this.props.setApprovedDate("day", e.target.value)}
                                />
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("cardPreparation.author")}</div>
                            <div className="col">

                                <input type="text" value={this.props.cardAuthor} name="cardAuthor"
                                       disabled={!this.props.enabled}
                                       onChange={(e) => this.props.setCardAuthor(e.target.value)}/>
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("cardPreparation.authoredDate")}</div>
                            <div className="col">
                                <input placeholder="Rok" type="number" name="cardAuthoredDateYear"
                                       disabled={!this.props.enabled}
                                       value={this.props.cardAuthoredDateYear}
                                       onChange={(e) => this.props.setCardAuthoredDate("year", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Miesiąc" type="number" name="cardAuthoredDateMonth"
                                       disabled={!this.props.enabled}
                                       value={this.props.cardAuthoredDateMonth}
                                       onChange={(e) => this.props.setCardAuthoredDate("month", e.target.value)}
                                />
                            </div>
                            <div className="col">
                                <input placeholder="Dzień" type="number" name="cardAuthoredDateDay"
                                       disabled={!this.props.enabled}
                                       value={this.props.cardAuthoredDateDay}
                                       onChange={(e) => this.props.setCardAuthoredDate("day", e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardPrepartionEditableField)

