import React from 'react'
import {connect} from "react-redux";

import {getAclIdentities, getUserGroup, saveUserGroup, toggleAclPriv} from "./usersDuck";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        userGroup: state.users.userGroup,
        aclIdentities: state.users.aclIdentities
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getUserGroup: () => dispatch(getUserGroup(ownProps.match.params.id)),
        saveUserGroup: () => dispatch(saveUserGroup()),
        getAclIdentities: () => dispatch(getAclIdentities()),
        toggleAclPriv: (type, identity, priv) => dispatch(toggleAclPriv(type, identity, priv))
    }
};

class UserGroupEditComponent extends React.Component {

    componentDidMount() {


        this.props.getUserGroup();
        this.props.getAclIdentities();
    }

    //
    // goToCreateNew() {
    //     window.location.href = "/artwork/create";
    // }
    //
    // isUserGroupSelected(ug) {
    //     return this.props.user.userGroups.find(value => value.id == ug.id) != undefined
    // }


    hasPermissionForFolder(folderId, permission) {
        return this.props.userGroup.rolePermissions.folders[folderId] && this.props.userGroup.rolePermissions.folders[folderId].find(p => p == permission) != undefined
    }

    hasPermissionForModule(folderId, permission) {
        return this.props.userGroup.rolePermissions.modules[folderId] && this.props.userGroup.rolePermissions.modules[folderId].find(p => p == permission) != undefined
    }

    hasPermissionForDictionary(folderId, permission) {
        return this.props.userGroup.rolePermissions.dictionaries[folderId] && this.props.userGroup.rolePermissions.dictionaries[folderId].find(p => p == permission) != undefined
    }


    render() {

        if (!this.props.userGroup || !this.props.aclIdentities) {

            return null;
        }


        let modulesTable = this.props.aclIdentities.modules.map(moduleName => (
            <tr key={moduleName}>
                <td>{moduleName}</td>
                <td><input type="checkbox" checked={this.hasPermissionForModule(moduleName, "READ")}
                           onChange={() => this.props.toggleAclPriv("modules", moduleName, "READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForModule(moduleName, "DOCS_READ")}
                           onChange={() => this.props.toggleAclPriv("modules", moduleName, "DOCS_READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForModule(moduleName, "DOCS_CREATE")}
                           onChange={() => this.props.toggleAclPriv("modules", moduleName, "DOCS_CREATE")}/></td>
            </tr>
        ));

        let collectionsTable = this.props.aclIdentities.collections.map(folder => (
            <tr key={folder.id}>
                <td>{folder.labels.pl}</td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "READ")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "READ")}/></td>
                <td><input type="checkbox" disabled={true}/></td>
                <td><input type="checkbox" disabled={true}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_READ")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_CREATE")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_CREATE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_UPDATE")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_UPDATE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_DELETE")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_DELETE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_REMOVE_CHILDREN")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_REMOVE_CHILDREN")}/>
                </td>
            </tr>
        ));

        let labelsTable = this.props.aclIdentities.labels.sort((a, b) => {
            return a.displayOrder > b.displayOrder ? 1 : -1
        }).map(folder => (
            <tr key={folder.id}>
                <td>{folder.labels.pl ? folder.labels.pl : folder.id}</td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "READ")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "READ")}/></td>
                <td><input type="checkbox" disabled={true}/></td>
                <td><input type="checkbox" disabled={true}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_READ")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_CREATE")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_CREATE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForFolder(folder.id, "DOCS_REMOVE_CHILDREN")}
                           onChange={() => this.props.toggleAclPriv("folders", folder.id, "DOCS_REMOVE_CHILDREN")}/>
                </td>
            </tr>
        ));


        let dictionariesTable = this.props.aclIdentities.dictionaries.map(dictionary => (
            <tr key={dictionary}>
                <td>{dictionary}</td>
                <td><input type="checkbox" checked={this.hasPermissionForDictionary(dictionary, "READ")}
                           onChange={() => this.props.toggleAclPriv("dictionaries", dictionary, "READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForDictionary(dictionary, "DOCS_READ")}
                           onChange={() => this.props.toggleAclPriv("dictionaries", dictionary, "DOCS_READ")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForDictionary(dictionary, "DOCS_CREATE")}
                           onChange={() => this.props.toggleAclPriv("dictionaries", dictionary, "DOCS_CREATE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForDictionary(dictionary, "DOCS_UPDATE")}
                           onChange={() => this.props.toggleAclPriv("dictionaries", dictionary, "DOCS_UPDATE")}/></td>
                <td><input type="checkbox" checked={this.hasPermissionForDictionary(dictionary, "DOCS_DELETE")}
                           onChange={() => this.props.toggleAclPriv("dictionaries", dictionary, "DOCS_DELETE")}/></td>
            </tr>
        ));


        return (
            <div className="row">
                <div className="col">

                    <h1>{this.props.userGroup.userGroup.name.pl}</h1>


                    Opis:

                    {this.props.userGroup.userGroup.description.pl}

                    Uprawniani

                    <div>
                        <h3>Moduły</h3>
                        <table>
                            <thead>
                            <tr>
                                <td>Nazwa</td>
                                <td>Widoczność</td>
                                <td>Oglądanie zawartości</td>
                                <td>Dodawanie zawartości</td>
                            </tr>
                            </thead>
                            <tbody>
                            {modulesTable}
                            </tbody>
                        </table>
                    </div>


                    <div>
                        <h3>Kolekcje</h3>
                        <table>
                            <thead>
                            <tr>
                                <td>Nazwa</td>
                                <td>Widoczność</td>
                                <td>Edytowanie</td>
                                <td>Usuwanie</td>
                                <td>Oglądanie zawartości</td>
                                <td>Dodawanie zawartości</td>
                                <td>Edytowanie zawartości</td>
                                <td>Usuwanie zawartości</td>
                                <td>Odłączanie zawartości</td>
                            </tr>
                            </thead>
                            <tbody>
                            {collectionsTable}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h3>Etykiety</h3>
                        <table>
                            <thead>
                            <tr>
                                <td>Nazwa</td>
                                <td>Widoczność</td>
                                <td>Edytowanie</td>
                                <td>Usuwanie</td>
                                <td>Oglądanie zawartości</td>
                                <td>Dodawanie zawartości</td>
                                <td>Odłączanie zawartości</td>
                            </tr>
                            </thead>
                            <tbody>
                            {labelsTable}
                            </tbody>
                        </table>
                    </div>

                    <div>
                        <h3>Słowniki</h3>
                        <table>
                            <thead>
                            <tr>
                                <td>Nazwa</td>
                                <td>Widoczność</td>
                                <td>Oglądanie zawartości</td>
                                <td>Dodawanie zawartości</td>
                                <td>Edytowanie zawartości</td>
                                <td>Usuwanie zawartości</td>
                            </tr>
                            </thead>
                            <tbody>
                            {dictionariesTable}
                            </tbody>
                        </table>
                    </div>


                    <button type="button" className="btn btn-primary"
                            onClick={event => this.props.saveUserGroup()}>Zapisz
                    </button>

                </div>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserGroupEditComponent)

