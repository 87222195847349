import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {getFilterAvailableOptions, loadAvailableOptions, setContent, setTitle} from "./newsDuck";
import {Editor} from '@tinymce/tinymce-react';
import {tinyMceOptions} from "../components/item/SimpleEditableField";

const mapStateToProps = (state, ownProps) => {
    return {
        lang: ownProps.lang,
        title: state.news.news.title[ownProps.lang],
        content: state.news.news.content[ownProps.lang],
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setTitle: (value) => dispatch(setTitle(ownProps.lang, value)),
        setContent: (value) => dispatch(setContent(ownProps.lang, value)),
    }
};

class NewsEditLanguage extends React.Component {

    handleEditorChange = (content) => {
        this.props.setContent(content)
    };

    handleTitleEditorChange = (content) => {
        this.props.setTitle(content)
    };


    render() {
        return (
            <div className="col-xl-5">
                <div className="component">
                    <header className="component-header">
                        <Translation>{t =>
                            <span>{t("title")} {this.props.lang}</span>
                        }</Translation>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content">
                        <textarea
                            className="full-width"
                            name={"titlePL"}
                            value={this.props.title}
                            onChange={(e) => this.handleTitleEditorChange(e.target.value)}
                        />
                    </div>
                </div>

                <div className="component">
                    <header className="component-header">
                        <Translation>{t =>
                            <span>{t("content")} {this.props.lang}</span>
                        }</Translation>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content">
                        <Editor
                            apiKey="o1p8a6a82ye3v8z03yctvwp70y3iyo8lst7tamh2a7zeibhd"
                            initialValue={this.props.content}
                            init={{
                                height: 450,
                                menubar: false,
                                plugins: tinyMceOptions.plugins,
                                toolbar: tinyMceOptions.toolbar,
                            }}
                            onEditorChange={(content) => this.handleEditorChange(content)}
                        />
                    </div>
                </div>
            </div>

        )
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(NewsEditLanguage)

