import React from 'react'
import {connect} from "react-redux";
import {setAttribute} from "../../actions/artworkActions";
import {Translation} from "react-i18next";


const mapStateToProps = (state, ownProps) => {
    return {
        visibleInNavigart: state.artworks.artwork.visibleInNavigart
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttr: (value) => dispatch(setAttribute("visibleInNavigart", value)),
    }
};

class VisibleInNavigartEditableField extends React.Component {


    render() {

        return (
            <Translation>{t =>
                <div style={{marginTop: "20px"}}>
                    <span>{t("visibleInNavigart")}</span> <input type="checkbox"
                                                                 id="visibleInNavigart"
                                                                 checked={this.props.visibleInNavigart}
                                                                 onChange={(e) => this.props.setAttr(e.target.checked)}/>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(VisibleInNavigartEditableField)

