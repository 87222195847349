import React from 'react'
import {connect} from "react-redux";
import BrowseTableComponent from "../../engine/component/BrowseTableComponent";
import * as actions from "../../actions/browseActions";
import SearchToolbarComponent from "../../components/browse/SearchArtworksComponent";
import PaginateWrapperComponent from "../../components/browse/PaginateWrapperComponent";
import {MEMBERS} from "../../components/browse/BrowseType";
import {Translation} from "react-i18next";
import {openClosePickupWindow} from "../../actions/filtersActions";
import ArtworksQueries from "../../components/browse/ArtworksQueries";
import {getUserPermissions} from "../../engine/selectors";
import {withRouter} from "react-router-dom";
import {membersListTableConfiguration} from "./membersListTable.config";
import {membersFilterAvailable} from "./memberFiltersConfig";

const mapStateToProps = (state) => {
    return {
        content: state.browse[MEMBERS].results.content,
        canCreate: getUserPermissions(state, "modules", "artwork").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getArtworks: (page) => dispatch(actions.setPageAndSearch(page, MEMBERS)),
        openPickUpWindow: (value) => dispatch(openClosePickupWindow(true, membersFilterAvailable)),
    }
};

class MembersList extends React.Component {

    componentDidMount() {
        if (!this.props.content || this.props.content.length == 0) {
            this.props.getArtworks(0);
        }
    }

    goToCreateNew() {
        this.props.history.push("/member/create");
    }

    render() {

        return (
            <div className="row justify-content-between">
                <sidebar className="col-md-2 artwork-sidebar">
                    <div>
                        <ArtworksQueries browseType={MEMBERS}/>
                        {/*<MemberFiltersSidebar browseType={MEMBERS}/>*/}
                    </div>
                </sidebar>
                <main className="col-md-10 content">
                    <div className="row">
                        <h2>Lista członków PAU</h2>
                    </div>
                    <SearchToolbarComponent filtersAvailable={membersFilterAvailable} browseType={MEMBERS}/>

                    <div className="row d-flex align-items-center">
                        <Translation>{t =>

                            <React.Fragment>
                                <div className="col header-actions-menu">
                                    {this.props.canCreate && <button type="button" className="btn btn-primary"
                                                                     onClick={() => this.goToCreateNew()}>{t("new...")}
                                    </button>}
                                    <button type="button" className="btn btn-outline-primary refresh"
                                            onClick={() => this.props.getArtworks(0)}>
                                        <i className="fas fa-sync-alt"></i></button>
                                    <button type="button" className="btn btn-outline-primary delete"><i
                                        className="fas fa-trash-alt"></i></button>
                                    <button type="button" className="btn btn-outline-primary"><i
                                        className="fas fa-print"></i>
                                    </button>
                                </div>

                                <div className="col header-actions-menu">
                                    {/*<ArtworksBulkActionsComponent/>*/}
                                </div>

                                <div className="col col-sm-1 header-actions-menu">
                                    <button type="button" className="btn btn-outline-primary"
                                            onClick={() => this.props.openPickUpWindow()}>
                                        <i className="fas fa-filter"></i>
                                    </button>

                                </div>
                            </React.Fragment>
                        }</Translation>
                        <div className="col-md-6 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={MEMBERS} showCounter={true}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <BrowseTableComponent tableConfig={membersListTableConfiguration}
                                                  browseType={MEMBERS}
                                                  records={this.props.content}/>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col">
                        </div>
                        <div className="col-md-4 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={MEMBERS}/>
                        </div>
                    </div>
                </main>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(MembersList))

