import React from 'react'
import {connect} from "react-redux";
import BrowseTableComponent from "../../../engine/component/BrowseTableComponent";
import {Translation} from "react-i18next";
import {IMAGE_IMPORT_RESULTS} from "../../browse/BrowseType";
import * as actions from "../../../actions/browseActions";
import PaginateWrapperComponent from "../../browse/PaginateWrapperComponent";
import {importResultsHistoryTableConfiguration} from "./importResultsHistoryTable.config";

const mapStateToProps = (state) => {
    return {
        content: state.browse[IMAGE_IMPORT_RESULTS].results.content,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getEntries: (page) => dispatch(actions.setPageAndSearch(page, IMAGE_IMPORT_RESULTS)),
    }
};

class ImportHistory extends React.Component {

    componentDidMount() {
        this.props.getEntries(0);
    }

    goToCreateNew() {
        window.location.href = "/dictionary-entry/create";
    }

    render() {

        return (
            <div className="row justify-content-between">
                <main className="col-md-10 content">
                    <div className="row">
                        <h2>Historia importów</h2>
                    </div>


                    <div className="row d-flex align-items-center">
                        <Translation>{t =>

                            <React.Fragment>
                                <div className="col header-actions-menu">

                                    <button type="button" className="btn btn-outline-primary refresh"
                                            onClick={() => this.props.getEntries(0)}>
                                        <i className="fas fa-sync-alt"></i></button>
                                </div>

                                <div className="col header-actions-menu">

                                </div>

                                <div className="col col header-actions-menu">


                                </div>
                            </React.Fragment>
                        }</Translation>
                        <div className="col-md-6 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={IMAGE_IMPORT_RESULTS}/>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col">
                            <BrowseTableComponent tableConfig={importResultsHistoryTableConfiguration}
                                                  browseType={IMAGE_IMPORT_RESULTS}
                                                  records={this.props.content}/>
                        </div>
                    </div>
                    <div className="row">

                        <div className="col">
                        </div>
                        <div className="col-md-4 pagination-react-paginate">
                            <PaginateWrapperComponent browseType={IMAGE_IMPORT_RESULTS}/>
                        </div>
                    </div>
                </main>
            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImportHistory)

