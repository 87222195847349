import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import BrowseTableComponent from "../engine/component/BrowseTableComponent";
import {newsListTableConfiguration} from "./newsListTable.config";
import {getNewsList} from "./newsDuck";
import {getUserPermissions} from "../engine/selectors";

const mapStateToProps = (state) => {
    return {
        content: state.news.list,
        canCreate: getUserPermissions(state, "modules", "news").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getNewsList: () => dispatch(getNewsList()),
    }
};

class NewsList extends React.Component {

    componentDidMount() {
        if (!this.props.content || this.props.content.length == 0) {
            this.props.getNewsList(0);
        }
    }

    createNewArtwork() {
        window.location.href = "/news/create";
    }

    render() {

        return (
            <div className="row justify-content-between">
                <main className="col-md-12 content">
                    <div className="row">
                        <Translation>{t =>
                            <h2>{t("newsList")}</h2>
                        }</Translation>
                    </div>

                    <div className="row d-flex align-items-center">
                        <Translation>{t =>
                            <React.Fragment>
                                <div className="col header-actions-menu">
                                    {this.props.canCreate && <button type="button" className="btn btn-primary"
                                                                     onClick={() => this.createNewArtwork()}>{t("new...")}
                                    </button>}
                                </div>
                            </React.Fragment>
                        }</Translation>
                    </div>

                    <div className="row">
                        <div className="col">
                            <BrowseTableComponent tableConfig={newsListTableConfiguration}
                                                  records={this.props.content}/>
                        </div>
                    </div>
                </main>
            </div>

        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsList)

