import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_ADD_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import {viewToDbMapper} from "../../components/item/OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "../../components/item/ToggleEditable";
import SelectizeEditableFieldInline from "./SelectizeEditableFieldInline";
import DateByPartsEditableField from "./DateByPartsEditableField";
import SimpleEditableFieldInline from "./SimpleEditableFieldInline";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "membershipAuPau"),
        country: getOptions(state, "country"),
        city: getOptions(state, "city"),
        unknown: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.unknown : false,
        comment: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.comment : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPlaceOfCreation: (type, entry) => dispatch(setAttributeSecondLevel("placeOfCreation", type, entry)),
        openCreateDictionaryEntryModal: (dictionaryType, label) => dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "placeOfCreation",
                subattr: dictionaryEntryTypeToFieldType(dictionaryType)
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.placeOfCreation && Array.isArray(state.artworks.artwork.placeOfCreation[type]) ?
    state.artworks.artwork.placeOfCreation[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];


const fieldTypeToDictionaryEntryType = (type) => {
    switch (type) {
        case "city":
            return "cities";
        case "country":
            return "countries";
        default:
            break;
    }
};

const dictionaryEntryTypeToFieldType = (type) => {
    switch (type) {
        case "cities":
            return "city";
        case "countries":
            return "country";
        default:
            break;
    }
};


class MembershipAuPau extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length - 1].label;
                this.props.openCreateDictionaryEntryModal(fieldTypeToDictionaryEntryType(type), label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setPlaceOfCreation(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("membershipAuPau")}</span>
                        <ToggleEditable fieldId={"membershipAuPau"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <SelectizeEditableFieldInline path="memberOfDepartment" enabledOverride={this.props.enabled}
                                                      dictionaryId="members_department"/>

                        <hr></hr>
                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div className="col form-label form-section-header">{t("Czł. korespondent krajowy")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="nationalCorrespondentDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="nationalCorrespondentGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>
                        <hr></hr>


                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div className="col form-label form-section-header">{t("Czł. czynny krajowy")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="countryMemberDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="countryMemberGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>

                        <hr></hr>

                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div className="col form-label form-section-header">{t("Czł. zagraniczny")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="abroadMemberDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadMemberGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadMemberPresidentApprovalDate"
                                                  label="zatwierdzenia przez Prezydenta" type="date"
                                                  enabled={this.props.enabled}/>

                        <hr></hr>


                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div
                                className="col form-label form-section-header">{t("Czł. korespondent zagraniczny")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="abroadCorrespondentDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadCorrespondentGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadCorrespondentPresidentApprovalDate"
                                                  label="zatwierdzenia przez Prezydenta" type="date"
                                                  enabled={this.props.enabled}/>

                        <hr></hr>

                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div className="col form-label form-section-header">{t("Czł. czynny zagraniczny")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="abroadActiveMemberDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadActiveMemberGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="abroadActiveMemberPresidentApprovalDate"
                                                  label="zatwierdzenia przez Prezydenta" type="date"
                                                  enabled={this.props.enabled}/>


                        <hr></hr>
                        <div className="row form-line">
                            <div className="col-lg-4 form-label">{t("Data wyboru")}</div>
                            <div className="col form-label form-section-header">{t("Czł. honorowy")}</div>
                        </div>
                        <DateByPartsEditableField labelSize="4" path="honoraryMemberDepartmentElectionDate"
                                                  label="na Wydziale" type="date" enabled={this.props.enabled}/>
                        <DateByPartsEditableField labelSize="4" path="honoraryMemberGeneralMeetingElectionDate"
                                                  label="na Walnym Zgromadzeniu" type="date"
                                                  enabled={this.props.enabled}/>

                        <hr></hr>
                        <SimpleEditableFieldInline path="membershipComments" type="text"
                                                   enabledOverride={this.props.enabled}/>

                    </div>

                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembershipAuPau)

