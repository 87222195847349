import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_ADD_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import {viewToDbMapper} from "./OtherAuthorsEditableField";
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "./ToggleEditable";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "placeOfCreation"),
        country: getOptions(state, "country"),
        city: getOptions(state, "city"),
        unknown: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.unknown : false,
        comment: state.artworks.artwork.placeOfCreation ? state.artworks.artwork.placeOfCreation.comment : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setPlaceOfCreation: (type, entry) => dispatch(setAttributeSecondLevel("placeOfCreation", type, entry)),
        openCreateDictionaryEntryModal: (dictionaryType, label) => dispatch(openCreateDictionaryEntryModal(dictionaryType, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "placeOfCreation",
                subattr: dictionaryEntryTypeToFieldType(dictionaryType)
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.placeOfCreation && Array.isArray(state.artworks.artwork.placeOfCreation[type]) ?
    state.artworks.artwork.placeOfCreation[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];


const fieldTypeToDictionaryEntryType = (type) => {
    switch (type) {
        case "city":
            return "cities";
        case "country":
            return "countries";
        default:
            break;
    }
};

const dictionaryEntryTypeToFieldType = (type) => {
    switch (type) {
        case "cities":
            return "city";
        case "countries":
            return "country";
        default:
            break;
    }
};


class PlaceOfCreationEditableField extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let label = inputValue[inputValue.length - 1].label;
                this.props.openCreateDictionaryEntryModal(fieldTypeToDictionaryEntryType(type), label);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setPlaceOfCreation(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("placeOfCreation")}</span>
                        <ToggleEditable fieldId={"placeOfCreation"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("placeOfCreation.unknown")}</div>
                            <div className="col">
                                <input type="checkbox"
                                       name="unknownPlaceOfCreation"
                                       disabled={!this.props.enabled}
                                       id="unknownPlaceOfCreation"
                                       checked={this.props.unknown}
                                       onChange={(e) => this.props.setPlaceOfCreation("unknown", e.target.checked)}
                                />
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("placeOfCreation.country")}</div>
                            <div className="col">
                                <AsyncCreatableSelect
                                    formatOptionLabel={function (data) {
                                        return (
                                            <span dangerouslySetInnerHTML={{__html: data.label}}/>
                                        );
                                    }}
                                    value={this.props.country}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    onChange={(inputValue, actionMeta) => this.handleChange("country", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("countries", inputValue)}
                                />
                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("placeOfCreation.city")}</div>
                            <div className="col">
                                <AsyncCreatableSelect
                                    formatOptionLabel={function (data) {
                                        return (
                                            <span dangerouslySetInnerHTML={{__html: data.label}}/>
                                        );
                                    }}
                                    value={this.props.city}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    placeholder={""}
                                    isDisabled={!this.props.enabled}
                                    onChange={(inputValue, actionMeta) => this.handleChange("city", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("cities", inputValue)}
                                />

                            </div>
                        </div>

                        <div className="row form-line">
                            <div className="col-lg-2 form-label">{t("placeOfCreation.comment")}</div>
                            <div className="col">
                                            <textarea
                                                disabled={!this.props.enabled}
                                                className="full-width"
                                                name="placeOfCreationComment"
                                                value={this.props.comment}
                                                onChange={(e) => this.props.setPlaceOfCreation("comment", e.target.value)}
                                            />
                            </div>
                        </div>
                    </div>

                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PlaceOfCreationEditableField)

