import React from 'react'
import {connect} from "react-redux";
import {importAssets} from "../../../actions/importActions";
import BrowseTableComponent from "../../../engine/component/BrowseTableComponent";
import {importResultsTableConfiguration} from "./importResultsTable.config";
import {Translation} from "react-i18next";

const mapStateToProps = (state) => {
    return {
        isLoading: state.imports.isLoading,
        results: state.imports.results
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        importAssets: (dryRun) => dispatch(importAssets(dryRun))
    }
};

class Import extends React.Component {

    render() {

        let message = null;

        let resultsTable = null;

        if (this.props.results && Array.isArray(this.props.results) && this.props.results.length > 0) {
            resultsTable = (
                <BrowseTableComponent tableConfig={importResultsTableConfiguration} records={this.props.results}/>
            );

            message = (
                <Translation>{t =>
                    <div className="alert alert-secondary" role="alert">
                        {t("importResultsBelow")}
                    </div>
                }</Translation>
            );
        }
        else if (this.props.results && Array.isArray(this.props.results)) {
            message = (
                <Translation>{t =>
                    <div id="no-files-found-message" className="alert alert-warning" role="alert">
                        {t("No files found.")}
                    </div>
                }</Translation>
            )
        }

        return (
            <div className={(this.props.isLoading ? " loading" : "")}>
                <h3>Import</h3>

                {/*<div className="alert alert-secondary" role="alert">*/}
                {/*    Files from import directory will be imported.*/}
                {/*</div>*/}

                <Translation>{t =>
                    <div>
                        <button id="start-import-trigger-dry-run" type="button" className="btn btn-primary"
                                onClick={() => this.props.importAssets(true)}>
                            {t("start_test_import")}
                        </button>
                        <button id="start-import-trigger" type="button" className="btn btn-danger"
                                onClick={() => this.props.importAssets(false)}>
                            {t("start_import")}
                        </button>
                    </div>
                }</Translation>

                {message}

                {resultsTable}

            </div>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Import)

