import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {getUserPermissions} from "../../engine/selectors";
import {getPerson, savePerson, setDescription, setName} from "./personDuck";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        person: state.persons.person,
        canCreate: getUserPermissions(state, "modules", "person").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getDictionaryEntry: () => dispatch(getPerson(ownProps.match.params.id)),
        setName: (value) => dispatch(setName(value)),
        setDescription: (value) => dispatch(setDescription(value)),
        savePerson: () => dispatch(savePerson()),
    }
};

class NewsEdit extends React.Component {

    componentDidMount() {
        this.props.getDictionaryEntry();
    }

    handleEditorChange = (content) => {
        this.props.setDescription(content)
    };

    handleTitleEditorChange = (content) => {
        this.props.setName(content)
    };


    goBack() {
        window.location.href = "/persons/";
    }


    render() {
        if (!this.props.person || !this.props.canCreate) {
            return null
        }


        let headerName = this.props.id ? "personEdit" : "personCreate"

        let valid = this.props.person.name != ""

        return (
            <div className="row justify-content-between">
                <main className="col-md-12 content">
                    <div className="row">
                        <Translation>{t =>
                            <h2>{t(headerName)}</h2>
                        }</Translation>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={this.goBack}><i
                                className="fas fa-angle-left"></i></button>
                            <button type="button" className="btn btn-primary" disabled={!valid}
                                    onClick={event => this.props.savePerson()}>Zapisz
                            </button>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-xl-5">
                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("name")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content">
                                    <textarea
                                        className="full-width"
                                        name={"name"}
                                        value={this.props.person.name}
                                        onChange={(e) => this.props.setName(e.target.value)}
                                    />
                                </div>
                            </div>


                        </div>

                        <div className="col-xl-5">
                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("description")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content">
                                    <textarea
                                        className="full-width"
                                        name={"name"}
                                        value={this.props.person.description}
                                        onChange={(e) => this.props.setDescription(e.target.value)}
                                    />
                                </div>
                            </div>


                        </div>
                    </div>

                </main>
            </div>

        )
    }
}

function mapDictionaryEntry(entry) {
    return {
        label: entry.labels.pl,
        value: entry
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsEdit)

