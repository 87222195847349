import React from 'react'
import {connect} from "react-redux";
import {Translation} from "react-i18next";
import {getNews, saveNews, setContent, setLangVersion, setPublicationDate, setTitle} from "./newsDuck";
import Select from "react-select";
import NewsEditLanguage from "./NewsEditLanguageComponent";
import {commonDataLoadDictionaryEntries, selectCommonDataDictionaryEntries} from "../common/commonDuck";
import {getUserPermissions} from "../engine/selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        news: state.news.news,
        dictionaryEntries: selectCommonDataDictionaryEntries(state, "languageVersions").map(mapDictionaryEntry),
        canCreate: getUserPermissions(state, "modules", "news").DOCS_CREATE
    };
};
const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        getNews: () => dispatch(getNews(ownProps.match.params.id)),
        loadLanguageVersions: () => dispatch(commonDataLoadDictionaryEntries("languageVersions")),
        setLangVersion: (dictionaryEntry) => dispatch(setLangVersion(dictionaryEntry)),
        setTitle: (lang, value) => dispatch(setTitle(lang, value)),
        setContent: (lang, value) => dispatch(setContent(lang, value)),
        saveNews: () => dispatch(saveNews()),
        setPublicationDate: (date) => dispatch(setPublicationDate(date)),
    }
};

class NewsEdit extends React.Component {

    componentDidMount() {
        this.props.getNews();
        this.props.loadLanguageVersions();
    }

    handleChange = (inputValue, actionMeta) => {

        switch (actionMeta.action) {
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setLangVersion(inputValue.value);
            default:
                break;
        }

    };

    handleEditorChange = (lang, content) => {
        this.props.setContent(lang, content)
    };

    handleTitleEditorChange = (lang, content) => {
        this.props.setTitle(lang, content)
    };

    setFromDate(yearMonthDay, value) {
        let from = {...this.props.news.datePublished.from}

        from[yearMonthDay] = value;


        this.props.setPublicationDate(from);
    }


    goBack() {
        window.location.href = "/news/";
    }


    render() {
        if (!this.props.news || !this.props.canCreate) {
            return null
        }


        let headerName = this.props.id ? "newsEdit" : "newsCreate"

        let valid = (this.props.news.title.pl != "" || this.props.news.title.en != "") && this.props.news._langVersion
            && this.props.news.datePublished.from.year != "" && this.props.news.datePublished.from.month != "" && this.props.news.datePublished.from.day != ""

        return (
            <div className="row justify-content-between">
                <main className="col-md-12 content">
                    <div className="row">
                        <Translation>{t =>
                            <h2>{t(headerName)}</h2>
                        }</Translation>
                    </div>
                    <div className="row">
                        <div className="col header-actions-menu">
                            <button type="button" className="btn btn-outline-primary" onClick={this.goBack}><i
                                className="fas fa-angle-left"></i></button>
                            <button type="button" className="btn btn-primary" disabled={!valid}
                                    onClick={event => this.props.saveNews()}>Zapisz
                            </button>
                        </div>
                    </div>


                    <div className="row">
                        <div className="col-xl-5">

                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("languageVersions")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content">
                                    <Select
                                        name="visibleInNavigart"
                                        value={this.props.news._langVersion ? mapDictionaryEntry(this.props.news._langVersion) : null}
                                        onChange={(inputValue, actionMeta) => this.handleChange(inputValue, actionMeta)}
                                        isClearable={false}
                                        defaultOptions
                                        options={this.props.dictionaryEntries}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="col-xl-5">

                            <div className="component">
                                <header className="component-header">
                                    <Translation>{t =>
                                        <span>{t("publicationDate")}</span>
                                    }</Translation>
                                </header>
                                <div className="message">

                                </div>
                                <div className="component-content content-padding">

                                    <div className="row form-line">
                                        <div className="col">
                                            <input placeholder="Rok" type="number" name="dateOfCreationFromYear"
                                                   value={this.props.news.datePublished.from.year}
                                                   onChange={(e) => this.setFromDate("year", e.target.value)}
                                            />
                                        </div>
                                        <div className="col">
                                            <input placeholder="Miesiąc" type="number" name="dateOfCreationFromMonth"
                                                   value={this.props.news.datePublished.from.month}
                                                   onChange={(e) => this.setFromDate("month", e.target.value)}
                                            />
                                        </div>
                                        <div className="col">
                                            <input placeholder="Dzień" type="number" name="dateOfCreationFromDay"
                                                   value={this.props.news.datePublished.from.day}
                                                   onChange={(e) => this.setFromDate("day", e.target.value)}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="row">
                        <NewsEditLanguage lang="pl"/>
                        <NewsEditLanguage lang="en"/>
                    </div>

                </main>
            </div>

        )
    }
}

function mapDictionaryEntry(entry) {
    return {
        label: entry.labels.pl,
        value: entry
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsEdit)

