//visibility: visible (default), hidden, dynamic
//default component visibility is visible


import {BACKEND_URL} from "../../../config";

export const databaseConfig = {
    id: "databaseMain",
    type: "component",
    logical: true,
    route: {
        path: "/administration/database",
        exact: true
    },
    children: [
        {
            id: "databaseMainHeader",
            type: "header",
            content: "Database",

        },
        {
            id: "migrateFoldersBtn",
            type: "button",
            label: "Migrate folders",
            action: {
                children: [
                    {
                        type: "call-rest",
                        url: BACKEND_URL + 'migration/folders',
                        setResponseToComponent: "exportResultsTable",
                        successAction: {

                        },
                        errorAction: {

                        }
                    }
                ]
            }
        },
        {
            id: "migrateExportFlagBtn",
            type: "button",
            label: "Migrate export flag",
            action: {
                children: [
                    {
                        type: "call-rest",
                        url: BACKEND_URL + 'migration/export-flag',
                        setResponseToComponent: "exportResultsTable",
                        successAction: {

                        },
                        errorAction: {

                        }
                    }
                ]
            }
        },
    ]
}
