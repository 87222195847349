import React from 'react'
import {connect} from "react-redux";
import {ARTWORK_ADD_ATTRIBUTE_SECOND_LVL, setAttributeSecondLevel} from "../../actions/artworkActions";
import AsyncCreatableSelect from "react-select/async-creatable";
import {getDictionaryEntriesByLabel} from "../../api/dictionaryEntry.api";
import {viewToDbMapper} from "./OtherAuthorsEditableField";

import {tinyMceOptions} from "./SimpleEditableField";
import {Editor} from '@tinymce/tinymce-react';
import {openCreateDictionaryEntryModal} from "../../actions/dictionaryEntriesActions";
import {Translation} from "react-i18next";
import {toSelectizeDictionaryEntry} from "../../util/dictionaryEntryUtils";
import {selectFieldEditEnabled} from "../../reducers/artworkReducer";
import ToggleEditable from "./ToggleEditable";


const mapStateToProps = (state) => {

    return {
        enabled: selectFieldEditEnabled(state, "description"),
        references: getOptions(state, "references"),
        tags: getOptions(state, "tags"),
        description: state.artworks.artwork.description ? state.artworks.artwork.description.description : null
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        setAttributeSecondLevel: (type, value) => dispatch(setAttributeSecondLevel("description",type, value)),
        openCreateDictionaryEntryModal: (type, label) =>  dispatch(openCreateDictionaryEntryModal(type, label, {
            actionType: ARTWORK_ADD_ATTRIBUTE_SECOND_LVL,
            payload: {
                attr: "description",
                subattr: type
            }
        })),
    }
};


const getOptions = (state, type) => state.artworks.artwork.description && Array.isArray(state.artworks.artwork.description[type]) ?
    state.artworks.artwork.description[type].map((dictionaryEntry) => {
        return dictionaryEntry ? toSelectizeDictionaryEntry(dictionaryEntry) : null
    }) : [];



class DescriptionEditableField extends React.Component {

    handleChange = (type, inputValue, actionMeta) => {
        switch (actionMeta.action) {
            case "create-option":
                let newItem = inputValue[inputValue.length-1].label;
                this.props.openCreateDictionaryEntryModal(type, newItem);
                break;
            case "clear":
            case "select-option":
            case "remove-option":
            case "remove-value":
                this.props.setAttributeSecondLevel(type, viewToDbMapper(inputValue));
            default:
                break;
        }

    };


    handleEditorChange = (content, editor) => {
        this.props.setAttributeSecondLevel("description", content);
    }

    render() {

        return (
            <Translation>{t =>
                <div className="component">
                    <header className="component-header">
                        <span>{t("description.description")}</span>
                        <ToggleEditable fieldId={"description"}/>
                    </header>
                    <div className="message">

                    </div>
                    <div className="component-content content-padding">
                        <div className="row form-line">
                            <div className="col">
                                <Editor
                                    disabled={!this.props.enabled}
                                    apiKey="o1p8a6a82ye3v8z03yctvwp70y3iyo8lst7tamh2a7zeibhd"
                                    initialValue={this.props.description}
                                    init={{
                                        height: 150,
                                        menubar: false,
                                        plugins: tinyMceOptions.plugins,
                                        toolbar: tinyMceOptions.toolbar,
                                        branding: false
                                    }}
                                    onEditorChange={this.handleEditorChange}
                                />
                            </div>
                        </div>


                        <div className="row">
                            <div className="col form-label">{t("description.references")}</div>
                        </div>
                        <div className="row form-line">
                            <div className="col">
                                <AsyncCreatableSelect
                                    placeholder={""}
                                    formatOptionLabel={function (data) {
                                        return (
                                            <span dangerouslySetInnerHTML={{__html: data.label}}/>
                                        );
                                    }}
                                    key="description.references"
                                    classNamePrefix="lp-copy-sel"
                                    isDisabled={!this.props.enabled}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    value={this.props.references}
                                    onChange={(inputValue, actionMeta) => this.handleChange("references", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("references", inputValue)}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col form-label">{t("description.tags")}</div>
                        </div>
                        <div className="row form-line">
                            <div className="col">
                                <AsyncCreatableSelect
                                    formatOptionLabel={function (data) {
                                        return (
                                            <span dangerouslySetInnerHTML={{__html: data.label}}/>
                                        );
                                    }}
                                    key="description.tags"
                                    classNamePrefix="lp-copy-sel"
                                    placeholder={""}
                                    components={{DropdownIndicator: () => null, IndicatorSeparator: () => null}}
                                    isDisabled={!this.props.enabled}
                                    value={this.props.tags}
                                    onChange={(inputValue, actionMeta) => this.handleChange("tags", inputValue, actionMeta)}
                                    isMulti={true}
                                    defaultOptions
                                    loadOptions={(inputValue) => getDictionaryEntriesByLabel("tags", inputValue)}
                                />
                            </div>
                        </div>

                    </div>
                </div>
            }</Translation>
        )
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DescriptionEditableField)

